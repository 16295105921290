<template>
  <v-app>
    <v-main class="00ff1b2a3d" id="body">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style scoped>
#body {
  background-color: rgba(27, 42, 61, 255);
}
@media print {
  body {
    margin: -2rem 0 0 0;
  }
}
</style>
