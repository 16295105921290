<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="$store.state.cashier != ''">
      <div v-if="day_block > 0">
        <div
          v-if="
            partnerBalance.tempo_package == 0 ||
            partnerBalance.tempo_package >= 2 * partnerBalance.permanent_package
          "
          class="text-center mt-16 white--text"
        >
          <h2>Insufficient Balance, Please contact the Admin of this system</h2>
        </div>

        <div v-else>
          <div class="hidden-md-and-down">
            <div v-if="whichToShow == 'keno'">
              <v-app-bar elevation="0" app id="navBar" dark>
                <v-layout row wrap class="" style="font-size: 14px">
                  <v-flex xs2 class="" v-if="currentGame.length > 0">
                    <p>GAME# {{ currentGame[0].game_number }}</p>
                  </v-flex>
                  <v-flex xs2 class="mt-2 mr-2 pr-1 pl-1">
                    <p>
                      <v-progress-linear
                        v-model="timePercent"
                        height="8"
                      ></v-progress-linear>
                    </p>
                  </v-flex>
                  <v-flex
                    class="red--text"
                    xs2
                    v-if="minutes == '00' && parseInt(seconds) <= 20"
                  >
                    <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                  </v-flex>

                  <v-flex class="" xs2 v-else>
                    <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="verifyDialog = true"
                    >
                      <span class="text-capitalize">Verify</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="cancelDialog = true"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs1 class="pl-2">
                    <v-btn text x-small @click="tmhPopUp = true">
                      <span class="text-capitalize"
                        >TMH
                        <!-- {{
                        totalMoneyOnHand -
                        totalMoneyPaid -
                        paidKenoMoneyFromYesterday +
                        (spinTotalMoneyOnHand -
                          spinTotalMoneyPaid -
                          paidSpinMoneyFromYesterday)
                      }} -->
                        <!-- <span style="font-size: 8px">ETB</span> -->
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <!-- <v-spacer></v-spacer> -->

                <v-btn small text @click="whichToShow = 'spin'">
                  <span class="mr-2 text-capitalize orange--text text--darken-1"
                    >Switch to Spin</span
                  >
                </v-btn>
                <v-btn x-small text @click="getUnpaid" :loading="unpaidLoading">
                  <span class="text-capitalize red--text text--darken-1"
                    >Tickets
                  </span>
                </v-btn>
                <v-btn small text @click="reportTicketDialog = true">
                  <span class="mr-2 text-capitalize">Report keno</span>
                </v-btn>
                <v-btn small text @click="logoutClicked">
                  <span class="mr-2 text-capitalize">Logout</span>
                </v-btn>
                <a @click="helpDialog = true">Help</a>
              </v-app-bar>

              <v-layout row wrap class="ml-1 mt-0">
                <v-flex xs6 class="">
                  <v-layout
                    row
                    wrap
                    class="text-center mr-3"
                    justify-space-around
                  >
                    <v-flex
                      xs12
                      v-if="partnerBalance.tempo_package <= 1000"
                      class="pa-1 red--text"
                    >
                      <span style="font-size: 14px"
                        >Before the system shuts down, please let your manager
                        know that your package is low.
                      </span>
                    </v-flex>
                    <v-flex
                      xs1
                      v-for="number in 40"
                      :key="number"
                      class="ma-1 mt-0 mb-2"
                      id="numbers"
                    >
                      <div class="pt-2 pb-2" id="selectedNum">
                        <a @click="selectNumber(number)">
                          <div class="circle pt-1">
                            <span class="pa-1">{{ number }}</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <br />
                    </v-flex>
                    <v-flex
                      xs1
                      v-for="number in 40"
                      :key="number + 40"
                      class="ma-1"
                      id="numbers"
                      k
                    >
                      <div class="pt-2 pb-2" id="selectedNum">
                        <a @click="selectNumber(number + 40)">
                          <div class="circle pt-1">
                            <span class="pa-1">{{ number + 40 }}</span>
                          </div>
                        </a>
                      </div>
                    </v-flex>

                    <v-flex xs6 class="mt-2">
                      <v-layout row wrap class="">
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Even') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Even')">
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Even') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Even')">
                              <div>
                                <span class="pa-1 white--text">Even</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Odd') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Odd')">
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Odd') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Odd')">
                              <div>
                                <span class="pa-1 white--text">Odd</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('High') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('High')">
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('High') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('High')">
                              <div>
                                <span class="pa-1 white--text">High</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                        <v-flex xs4 class="ma-1 text-center" id="numbers">
                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Low') == -1
                            "
                            class="pt-2 pb-2"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Low')">
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>

                          <div
                            v-if="
                              selectedEvenOddAndHighLow.indexOf('Low') != -1
                            "
                            class="pt-2 pb-2 red"
                            id="selectedNum"
                          >
                            <a @click="selectEvenOddAndHighLow('Low')">
                              <div>
                                <span class="pa-1 white--text">Low</span>
                              </div>
                            </a>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout
                    row
                    wrap
                    class="mr-1"
                    v-if="
                      selectedNumbers.length > 0 ||
                      selectedEvenOddAndHighLow.length > 0
                    "
                  >
                    <!-- <v-flex xs12>
              <p style="font-size: 20px" class="white--text">
                Selected Numbers
              </p>
            </v-flex> -->
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-2 pb-2"
                      id="selectedNumbers"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pl-8 pt-1">
                          <a
                            class="pa-1 pt-2 pb-3 white--text"
                            dark
                            @click="
                              selectedNumbers = [];
                              selectedEvenOddAndHighLow = [];
                              evenOddInputMoney = [];
                              selectedNumberOptionCounter = 0;
                              numberInputMoney = [];
                            "
                          >
                            <span class="text-capitalize">Clear</span></a
                          >
                        </v-flex>

                        <v-flex xs6 class="text-center pl-3">
                          <a class="pa-1 pt-2 pb-3" @click="recietOverview('')">
                            <v-icon large class="white--text">print</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>

                      <!-- <v-btn
                id="printBtn"
                text
                dark
                class="pl-10 pr-10 red--text"
                v-if="selectedNumbers.length > 0"
                @click="selectedNumbers = []"
              >
                <span class="text-capitalize">Cancel</span>
              </v-btn> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(selectedNumber, i) in selectedNumbers"
                      :key="i + 100"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs8 class="pt-2">
                          <span class="pa-1">{{
                            selectedNumber.toString()
                          }}</span>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px"
                            v-model="numberInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            outlined
                            @click="
                              kenoCurrentMousePosition = i;
                              inWhichToAddAmount = 'number';
                            "
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs1 class="pr-3">
                          <a @click="removeSelectedNumbers(i)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      class="text-right mb-1 ml-0 pt-1 pb-1 mt-1 mb-4"
                      v-if="selectedNumbers.length > 0"
                    >
                      <a
                        class="pa-1 pt-2 pb-3"
                        style="border: 1px solid cyan; border-radius: 3px"
                        @click="
                          if (
                            selectedNumbers[selectedNumberOptionCounter]
                              .length > 0
                          ) {
                            selectedNumbers.push([]);
                            selectedNumberOptionCounter++;
                            kenoCurrentMousePosition++;
                            inWhichToAddAmount = 'number';
                          }
                        "
                      >
                        <v-icon class="white--text">add</v-icon>
                      </a>
                    </v-flex>

                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(evenOdd, i) in selectedEvenOddAndHighLow"
                      :key="i"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs8 class="pt-2">
                          <span class="pa-1">{{ evenOdd }}</span>
                        </v-flex>

                        <v-flex xs3>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px; color: black"
                            v-model="evenOddInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            @click="
                              kenoevenoddCurrentMousePosition = i;
                              inWhichToAddAmount = 'evenodd';
                            "
                            outlined
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs1 class="pr-3">
                          <a @click="removeEvenOddKenoList(evenOdd)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <!-- <v-flex xs4 class="mt-10 text-center">
             
            </v-flex> -->
                  </v-layout>
                </v-flex>

                <v-flex xs3>
                  <v-layout row wrap class="text-center mr-1">
                    <v-flex xs6 class="mt-0 pb-1">
                      <v-btn
                        :loading="gameLoading"
                        @click="getLastTwentyGames('keno')"
                        outlined
                        small
                        dark
                        text
                        class="white--text text-capitalize"
                      >
                        <span>Print 20 keno games</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="mt-0 pb-1">
                      <span class="red--text" style="font-size: 14px"
                        >{{ day_block }} Days left</span
                      >
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <a @click="applyToAll">
                        <v-card
                          dark
                          class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="applyToAll"
                        >
                          <span>Apply to All</span>
                        </v-card>
                      </a>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <div class="ma-1 mt-0">
                        <v-text-field
                          v-model="selectedCustomAmount"
                          class="white"
                          placeholder="Birr"
                          :rules="rules"
                          hide-details="true"
                          single-line
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex
                      dark
                      xs4
                      v-for="amount in customAmount"
                      :key="amount + 600"
                    >
                      <a @click="assignSelectedAmount(amount)">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="numbers"
                        >
                          <span>{{ amount }}</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex dark xs4>
                      <a @click="clearAll">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="clearBtn"
                        >
                          <span>Clear</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12>
                      <v-card class="ma-1 pa-1 pt-0">
                        <div id="ticketListVertically">
                          <h4
                            v-if="isDuplicated"
                            class="red--text"
                            style="transform: scaleX(-1)"
                          >
                            Ticket Duplicated
                          </h4>
                          <v-form
                            @submit.prevent=""
                            style="transform: scaleX(-1)"
                          >
                            <v-card-text style="font-size: 11px">
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-for="(lastGame, i) in lastTwoGames"
                                  :key="i"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(ticket, j) in lastGame.getTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{ lastGame.game_number }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />
                                      <span> {{ ticket.others }} </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="printAgain(ticket)"
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>

                                        <v-btn
                                          v-if="
                                            lastGame.game_status == 'pending'
                                          "
                                          text
                                          small
                                          class="pink--text"
                                          @click="
                                            cancelDuplicatedTicket(
                                              ticket,
                                              lastGame.getTickets,
                                              j
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Cancel
                                          </span>
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout
                                    row
                                    wrap
                                    v-for="(
                                      ticket, j
                                    ) in lastGame.getLastTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                    class="mt-7"
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{
                                          ticket.getGame[0].game_number
                                        }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />
                                      <span> {{ ticket.others }} </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="
                                            getCopy(ticket, ticket.getGame[0])
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-form>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-dialog
                v-model="printDialog"
                persistent
                max-width="450"
                v-if="currentGame.length > 0 && brachData != ''"
              >
                <v-card>
                  <v-card-text
                    v-if="brachData.ticket_layout == 'Layout 2'"
                    ref="printable"
                    style="font-family: Arial"
                  >
                    <!-- <img
                    height="50%"
                      contain
                      width="100%"
                      src="../assets/Keno-01.png"
                      alt=""
                    /> -->
                    <div
                      style="margin-left: 0px; color: black"
                      class="layoutWaterMark"
                    >
                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ userNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.brachData.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              copyMessage == "copy"
                                ? new Date(Number(gameDateToCopy))
                                : new Date(Number(currentGame[0].game_date))
                                  | formatDate
                            }}

                            (UTC +3)/(#{{
                              copyMessage == "copy"
                                ? "Copy"
                                : $store.state.kenoTicketNumber
                            }})
                          </span>
                        </div>

                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="brachData.logo != 'Normal' && brachData != ''"
                            :src="require(`../assets/${brachData.logo}.png`)"
                          />
                        </span>
                      </div>
                      <p
                        style="
                          clear: left;
                          float: left;
                          font-size: 11px;
                          text-align: left;
                        "
                      >
                        Keno {{ new Date(gameDate) | formatDate }} #{{
                          copyMessage == "copy"
                            ? gameNumberToCopy
                            : currentGame[0].game_number
                        }}
                      </p>

                      <table
                        style="
                          width: 100%;
                          text-align: left;
                          margin-top: 0;
                          font-size: 11px;
                        "
                      >
                        <tr>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            Choosen
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Odds
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Stake
                          </th>

                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Win
                          </th>
                        </tr>

                        <span
                          v-for="(selectedNumber, i) in selectedNumbers"
                          :key="i + 1000"
                        >
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <span
                              v-if="
                                odds.choosen_length == selectedNumber.length
                              "
                            >
                              <tr>
                                <td style="padding-top: 0px">
                                  {{ selectedNumber.toString() }}
                                </td>
                                <td style="padding-top: 0px">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(odds.odd_value.split(",")[0]) /
                                    10
                                  }}
                                </td>
                                <td style="padding-top: 0px">
                                  | &nbsp;&nbsp;{{ numberInputMoney[i] }}
                                </td>

                                <td style="padding-top: 0px">
                                  | &nbsp;&nbsp;{{
                                    (parseFloat(numberInputMoney[i]) *
                                      parseFloat(
                                        odds.odd_value.split(",")[0]
                                      )) /
                                    10
                                  }}
                                </td>
                              </tr>
                            </span>
                          </span>
                        </span>

                        <span
                          style="padding-top: 0px"
                          v-for="(other, i) in selectedEvenOddAndHighLow"
                          :key="i + 10001"
                        >
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <span
                              v-if="
                                odds.choosen_length.toLowerCase() ==
                                other.toLowerCase()
                              "
                            >
                              <tr>
                                <td style="padding-top: 0px">
                                  {{ other }}
                                </td>
                                <td style="padding-top: 0px">
                                  | &nbsp;&nbsp;{{ odds.odd_value }}
                                </td>
                                <td style="padding-top: 0px">
                                  | &nbsp;&nbsp;{{ evenOddInputMoney[i] }}
                                </td>

                                <td style="padding-top: 0px">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(evenOddInputMoney[i]) *
                                    parseFloat(odds.odd_value)
                                  }}
                                </td>
                              </tr>
                            </span>
                          </span>
                        </span>
                      </table>
                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ totalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table style="width: 100%; font-size: 11px">
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate
                      }}
                    </div>
                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="userNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                userNumber
                                  .toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>

                  <v-card-text
                    v-if="brachData.ticket_layout == 'Layout 1'"
                    ref="printable"
                    style="font-family: Arial"
                  >
                    <!-- <img
                  height="50%"
                    contain
                    width="100%"
                    src="../assets/Keno-01.png"
                    alt=""
                  /> -->
                    <div
                      style="margin-left: 0px; color: black"
                      class="layoutWaterMark"
                    >
                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ userNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.brachData.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              copyMessage == "copy"
                                ? new Date(Number(gameDateToCopy))
                                : new Date(Number(currentGame[0].game_date))
                                  | formatDate
                            }}

                            (UTC +3)/(#{{
                              copyMessage == "copy"
                                ? "Copy"
                                : $store.state.kenoTicketNumber
                            }})
                          </span>
                        </div>
                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="brachData.logo != 'Normal' && brachData != ''"
                            :src="require(`../assets/${brachData.logo}.png`)"
                          />
                        </span>
                      </div>

                      <table style="width: 100%; font-size: 11px">
                        <span
                          v-for="(selectedNumber, i) in selectedNumbers"
                          :key="i + 1000"
                        >
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <span
                              v-if="
                                odds.choosen_length == selectedNumber.length &&
                                numberInputMoney.length ==
                                  selectedNumbers.length
                              "
                            >
                              <tr>
                                <td style="font-size: 11px; text-align: left">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Keno {{ new Date(gameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : currentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{
                                    selectedNumber.toString()
                                  }}
                                  &nbsp;&nbsp;{{
                                    (
                                      parseFloat(odds.odd_value.split(",")[0]) /
                                      10
                                    ).toFixed(2)
                                  }}
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 11px">Br </span
                                  >{{ numberInputMoney[i] }}.00
                                  <span><br /></span
                                  ><span
                                    ><br />
                                    <br
                                  /></span>
                                </td>
                              </tr>
                            </span>
                          </span>
                        </span>

                        <span
                          style="padding-top: 2px"
                          v-for="(other, i) in selectedEvenOddAndHighLow"
                          :key="i + 10001"
                        >
                          <span
                            v-for="odds in $store.state.odds"
                            :key="odds.choosen_length"
                          >
                            <span
                              v-if="
                                odds.choosen_length.toLowerCase() ==
                                  other.toLowerCase() &&
                                selectedEvenOddAndHighLow.length ==
                                  evenOddInputMoney.length
                              "
                            >
                              <tr>
                                <td style="text-align: left; font-size: 11px">
                                  <span
                                    v-if="other == 'Even' || other == 'Odd'"
                                    style="font-weight: bold; font-size: 11px"
                                    >Even/Odd</span
                                  >
                                  <span
                                    v-if="other == 'High' || other == 'Low'"
                                    style="font-weight: bold; font-size: 11px"
                                    >High/Low</span
                                  >

                                  <br />
                                  Keno {{ new Date(gameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : currentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ other }}&nbsp;&nbsp;{{
                                    odds.odd_value
                                  }}.00
                                </td>

                                <td
                                  style="
                                    text-align: right;
                                    font-weight: bold;
                                    padding-top: 0px;
                                  "
                                >
                                  <span style="font-size: 11px">Br </span
                                  >{{ evenOddInputMoney[i] }}.00
                                  <span><br /></span><span><br /><br /></span>
                                </td>
                              </tr>
                            </span>
                          </span>
                        </span>
                      </table>
                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ totalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table style="width: 100%; font-size: 11px">
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ totalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(gameDate + 48 * 60 * 60 * 1000) | formatDate
                      }}
                    </div>

                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="userNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                userNumber
                                  .toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" class="text-capitalize" text>
                      Waiting...
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="printDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="isPlaying"
                persistent
                max-width="420"
                class="red"
              >
                <p
                  class="headline white--text pink pa-5"
                  style="border-radius: 5px"
                >
                  Keno is in playing mode, please try later! <br />
                  <v-btn small text @click="whichToShow = 'spin'">
                    <span
                      class="mr-2 text-capitalize white--text text--darken-1"
                      >Switch to Spin</span
                    >
                  </v-btn>
                </p>
              </v-dialog>

              <v-dialog v-model="verifyDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Verify user </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIDToVerify"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="verifyLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="verify"
                      >
                        verify
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          verifyDialog = false;
                          userIDToVerify = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="reportTicketDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Report ticket </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text class="pl-5 pr-5">
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIdForReport"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        hide-details="true"
                        prepend-icon="view_column"
                      ></v-text-field>

                      <v-text-field
                        class="mt-3"
                        hide-details="true"
                        style="font-size: 11px"
                        v-model="reportReason"
                        :rules="[(v) => !!v || 'Required']"
                        label="Write a reason"
                        required
                        dense
                        outlined
                        prepend-icon="note"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="reportLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="reportTicket"
                      >
                        report
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          reportTicketDialog = false;
                          userIdForReport = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="winnersDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Check ticket </v-card-title>

                  <v-card-text>
                    <P class="green--text" v-if="totalWinMoneyForOneUser > 0">
                      <v-icon class="green--text">check</v-icon>
                      User won total
                      <strong
                        v-if="verifyUserData[0].getJackpotHistory.length > 0"
                        >{{
                          totalWinMoneyForOneUser +
                          verifyUserData[0].getJackpotHistory[0].amount
                        }}</strong
                      >
                      <strong v-else>{{ totalWinMoneyForOneUser }}</strong>
                      Birr <br /><br />
                      <p
                        v-if="verifyUserData[0].getJackpotHistory.length > 0"
                        class="green--text"
                      >
                        {{ verifyUserData[0].getJackpotHistory[0].jack_type }}:
                        {{ verifyUserData[0].getJackpotHistory[0].amount }} Birr
                        Won
                      </p>

                      <div v-for="(wonList, i) in payableList" :key="i">
                        <span
                          class="red--text"
                          v-if="wonList.includes(' Loss ')"
                        >
                          {{ wonList }}
                        </span>

                        <span v-else>
                          {{ wonList }}
                        </span>
                      </div>
                    </P>
                    <P class="red--text" v-else
                      ><v-icon class="red--text">cancel</v-icon> User loss the
                      game!
                    </P>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="payLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="totalWinMoneyForOneUser > 0"
                      @click="payWinnerAward"
                    >
                      Pay
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="winnersDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="cancelDialog" persistent max-width="420" top>
                <v-card class="text-center pt-5">
                  <v-card-title> Cancel Ticket </v-card-title>

                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar to
                        cancel the ticket</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="userIDToCancel"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>

                      <P>Are you sure you want to cancel this ticket?</P>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="cancelLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="normalCancelTicket"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="cancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </div>

            <div v-if="whichToShow == 'spin'">
              <v-app-bar elevation="0" app id="navBar" dark>
                <v-layout row wrap class="" style="font-size: 14px">
                  <v-flex xs2 class="" v-if="spinCurrentGame.length > 0">
                    <p>GAME# {{ spinCurrentGame[0].game_number }}</p>
                  </v-flex>
                  <v-flex xs2 class="mt-2 mr-2 pr-1 pl-1">
                    <p>
                      <v-progress-linear
                        v-model="spinTimePercent"
                        height="8"
                      ></v-progress-linear>
                    </p>
                  </v-flex>
                  <v-flex
                    class="red--text"
                    xs2
                    v-if="spinMinutes == '00' && parseInt(spinSeconds) <= 20"
                  >
                    <p>GAME CLOSES IN {{ spinMinutes }}:{{ spinSeconds }}</p>
                  </v-flex>

                  <v-flex class="" xs2 v-else>
                    <p>GAME CLOSES IN {{ spinMinutes }}:{{ spinSeconds }}</p>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="SpinVerifyDialog = true"
                    >
                      <span class="text-capitalize">Verify</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1 class="pl-2">
                    <v-btn
                      outlined
                      rounded
                      x-small
                      @click="spinCancelDialog = true"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs1 class="pl-2">
                    <v-btn text x-small @click="tmhPopUp = true">
                      <span class="text-capitalize"
                        >TMH
                        <!-- {{
                        totalMoneyOnHand -
                        totalMoneyPaid -
                        paidKenoMoneyFromYesterday +
                        (spinTotalMoneyOnHand -
                          spinTotalMoneyPaid -
                          paidSpinMoneyFromYesterday)
                      }} -->
                        <!-- <span style="font-size: 8px">ETB</span> -->
                      </span>
                    </v-btn>
                  </v-flex>
                </v-layout>

                <v-btn small text @click="whichToShow = 'keno'">
                  <span class="mr-2 text-capitalize orange--text text--darken-1"
                    >Switch to Keno</span
                  >
                </v-btn>

                <v-btn x-small text @click="getUnpaid" :loading="unpaidLoading">
                  <span class="text-capitalize red--text text--darken-1"
                    >Tickets
                  </span>
                </v-btn>

                <v-btn small text @click="spinReportTicketDialog = true">
                  <span class="mr-2 text-capitalize">Report spin</span>
                </v-btn>
                <v-btn small text @click="logoutClicked">
                  <span class="mr-2 text-capitalize">Logout</span>
                </v-btn>
                <a @click="helpDialog = true">Help</a>
              </v-app-bar>

              <v-layout row wrap class="ml-1 mt-0">
                <v-flex xs6 class="">
                  <v-layout row wrap class="text-center">
                    <v-flex
                      xs12
                      v-if="partnerBalance.tempo_package <= 1000"
                      class="pa-1 red--text"
                    >
                      <span style="font-size: 14px"
                        >Before the system shuts down, please let your manager
                        know that your package is low.
                      </span>
                    </v-flex>
                    <v-flex xs4>
                      <v-layout row wrap>
                        <v-flex xs6>
                          <a @click="selectSpinNumbers('Red')">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Red</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('High')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>High</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('Even')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Even</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs6>
                          <a @click="selectSpinNumbers('Black')">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Black</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('Low')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Low</span>
                            </v-card></a
                          >
                          <a @click="selectSpinNumbers('Odd')">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Odd</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs12 class="mt-7">
                          <a @click="neighboursPopUp = true">
                            <v-card
                              class="cyan ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Neighbours</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs12 class="mb-5">
                          <a @click="twoNumbersPopUp = true">
                            <v-card
                              class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Two Numbers</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs6>
                          <a @click="selectSpinNumbers('High & Red')">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>High & Red</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('High & Black')">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>High & Black</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs6>
                          <a @click="selectSpinNumbers('Low & Black')">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Low & Black</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers('Low & Red')">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Low & Red</span>
                            </v-card></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs6>
                      <v-layout row wrap>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Twins')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Twins</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('0 Green')">
                            <v-card
                              class="green ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>0 Green</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="generateRandomBet">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Random</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number }}</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs2>
                          <a
                            v-for="number in 4"
                            :key="number"
                            @click="selectSpinNumbers(number + 6)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 6 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 6 }}</span>
                            </v-card>
                          </a>
                          <a @click="selectSpinNumbers(11)">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 11 }}</span>
                            </v-card></a
                          >
                          <a @click="selectSpinNumbers(12)">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 12 }}</span>
                            </v-card></a
                          >
                        </v-flex>

                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number + 12)"
                          >
                            <v-card
                              v-if="number % 2 != 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 12 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 12 }}</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number + 18)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 18 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 18 }}</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 4"
                            :key="number"
                            @click="selectSpinNumbers(number + 24)"
                          >
                            <v-card
                              v-if="number % 2 == 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 24 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 24 }}</span>
                            </v-card>
                          </a>

                          <a @click="selectSpinNumbers(29)">
                            <v-card
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 29 }}</span>
                            </v-card></a
                          >
                          <a @click="selectSpinNumbers(30)">
                            <v-card
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ 30 }}</span>
                            </v-card></a
                          >
                        </v-flex>
                        <v-flex xs2>
                          <a
                            v-for="number in 6"
                            :key="number"
                            @click="selectSpinNumbers(number + 30)"
                          >
                            <v-card
                              v-if="number % 2 != 0"
                              class="black ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 30 }}</span>
                            </v-card>
                            <v-card
                              v-else
                              class="red ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>{{ number + 30 }}</span>
                            </v-card>
                          </a>
                        </v-flex>

                        <v-flex xs4>
                          <a @click="selectSpinNumbers('1st Donzen')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>1st Donzen</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('2nd Donzen')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>2nd Donzen</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('3rd Donzen')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>3rd Donzen</span>
                            </v-card>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs1>
                      <a @click="selectSpinNumbers('12|21')">
                        <v-card
                          class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                          id="numbers"
                        >
                          <span
                            >12<br />
                            |<br />
                            21</span
                          >
                        </v-card>
                      </a>
                      <a @click="selectSpinNumbers('13|31')">
                        <v-card
                          class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                          id="numbers"
                        >
                          <span
                            >13 <br />|<br />
                            31</span
                          >
                        </v-card>
                      </a>
                      <a @click="selectSpinNumbers('23|32')">
                        <v-card
                          class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                          id="numbers"
                        >
                          <span
                            >23 <br />
                            |<br />
                            32</span
                          >
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12 class="mt-4 mb-4">
                      <v-layout row wrap>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector A')">
                            <v-card
                              class="orange darken-2 ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector A </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector B')">
                            <v-card
                              class="blue darken-4 ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector B </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector C')">
                            <v-card
                              class="purple ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector C</span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector D')">
                            <v-card
                              class="teal accent-3 ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span> Sector D </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector E')">
                            <v-card
                              class="yellow accent-2 ma-1 mt-0 mb-2 pt-2 pb-2 customamount black--text"
                              id="numbers"
                            >
                              <span> Sector E </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Sector F')">
                            <v-card
                              class="grey lighten-3 ma-1 mt-0 mb-2 pt-2 pb-2 customamount black--text"
                              id="numbers"
                            >
                              <span> Sector F </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 0')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 0 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 1')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 1 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 2')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 2 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 3')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 3 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 4')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 4 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 5')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 5 </span>
                            </v-card>
                          </a>
                        </v-flex>
                        <v-flex xs4>
                          <a @click="selectSpinNumbers('Final 6')">
                            <v-card
                              class="blue ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                              id="numbers"
                            >
                              <span>Final 6 </span>
                            </v-card>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3 class="pl-2 pb-2">
                  <v-layout
                    row
                    wrap
                    class="mr-3"
                    v-if="selectedSpinNumbers.length > 0"
                  >
                    <!-- <v-flex xs12>
              <p style="font-size: 20px" class="white--text">
                Selected Numbers
              </p>
            </v-flex> -->
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-2 pb-2"
                      id="selectedNumbers"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pl-8 pt-1">
                          <a
                            class="pa-1 pt-2 pb-3 white--text"
                            dark
                            @click="
                              selectedSpinNumbers = [];
                              spinInputMoney = [];
                            "
                          >
                            <span class="text-capitalize">Clear</span></a
                          >
                        </v-flex>

                        <v-flex xs6 class="text-center pl-3">
                          <a
                            class="pa-1 pt-2 pb-3"
                            @click="spinRecietOverview('')"
                          >
                            <v-icon large class="white--text">print</v-icon>
                          </a>
                        </v-flex>
                      </v-layout>

                      <!-- <v-btn
                id="printBtn"
                text
                dark
                class="pl-10 pr-10 red--text"
                v-if="selectedNumbers.length > 0"
                @click="selectedNumbers = []"
              >
                <span class="text-capitalize">Cancel</span>
              </v-btn> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="grey mb-1 ml-0 pt-1 pb-1"
                      id="selectedNumbers"
                      v-for="(selectedNumber, i) in selectedSpinNumbers"
                      :key="i"
                    >
                      <v-layout row wrap justify-space-around>
                        <v-flex xs6 class="pt-2">
                          <span class="pa-1">{{ selectedNumber }}</span>
                        </v-flex>
                        <v-flex xs4>
                          <v-text-field
                            type="number"
                            min="0"
                            style="font-size: 11px"
                            v-model="spinInputMoney[i]"
                            class="white"
                            placeholder="Birr"
                            :rules="rules"
                            hide-details="true"
                            single-line
                            outlined
                            @click="spinCurrentMousePosition = i"
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs1 class="pr-3">
                          <a @click="spinRemoveSelectedNumbers(i)"
                            ><v-icon small color="error" right class="white"
                              >cancel</v-icon
                            ></a
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs3 class="mt-0">
                  <v-layout row wrap class="text-center mr-1">
                    <v-flex xs6 class="mt-0 pb-1">
                      <v-btn
                        :loading="gameLoading"
                        @click="getLastTwentyGames('spin')"
                        outlined
                        small
                        dark
                        text
                        class="white--text text-capitalize"
                      >
                        <span>Print 20 spin games</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="mt-0 pb-1">
                      <span class="red--text" style="font-size: 14px"
                        >{{ day_block }} Days left</span
                      >
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <a @click="spinApplyToAll">
                        <v-card
                          dark
                          class="ma-1 mt-0 mb-2 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="applyToAll"
                        >
                          <span>Apply to All</span>
                        </v-card>
                      </a>
                    </v-flex>
                    <v-flex xs6 class="mt-0">
                      <div class="ma-1 mt-0">
                        <v-text-field
                          v-model="spinSelectedCustomAmount"
                          class="white"
                          placeholder="Birr"
                          :rules="rules"
                          hide-details="true"
                          single-line
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                    </v-flex>

                    <v-flex
                      dark
                      xs4
                      v-for="amount in customAmount"
                      :key="amount + 600"
                    >
                      <a @click="spinAssignSelectedAmount(amount)">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="numbers"
                        >
                          <span>{{ amount }}</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex dark xs4>
                      <a @click="spinclearAll">
                        <v-card
                          class="ma-1 mt-0 mb-1 pt-2 pb-2 pl-5 pr-5 customamount"
                          id="clearBtn"
                        >
                          <span>Clear</span>
                        </v-card>
                      </a>
                    </v-flex>

                    <v-flex xs12>
                      <v-card class="ma-1 pa-1 pt-0">
                        <div id="ticketListVertically">
                          <h4
                            v-if="spinIsDuplicated"
                            class="red--text"
                            style="transform: scaleX(-1)"
                          >
                            Ticket Duplicated
                          </h4>
                          <v-form
                            @submit.prevent=""
                            style="transform: scaleX(-1)"
                          >
                            <v-card-text style="font-size: 11px">
                              <v-layout row wrap>
                                <v-flex
                                  xs12
                                  v-for="(lastGame, i) in spinLastTwoGames"
                                  :key="i"
                                >
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(ticket, j) in lastGame.getTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                  >
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{ lastGame.game_number }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>
                                      <br />
                                      <span> {{ ticket.others }} </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="spinPrintAgain(ticket)"
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>

                                        <v-btn
                                          v-if="
                                            lastGame.game_status == 'pending'
                                          "
                                          text
                                          small
                                          class="pink--text"
                                          @click="
                                            spinCancelDuplicatedTicket(
                                              ticket,
                                              lastGame.getTickets,
                                              j
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Cancel ticket</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                  <v-layout
                                    row
                                    wrap
                                    v-for="(
                                      ticket, j
                                    ) in lastGame.getLastTickets"
                                    :key="ticket.ticket_id"
                                    justify-space-around
                                    class="mt-7"
                                  >
                                    <v-flex xs12 v-if="j != 0">
                                      <v-divider></v-divider>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      v-if="
                                        $store.state.cashier.cashier_id ==
                                        ticket.cashier_id
                                      "
                                    >
                                      <span>
                                        Game number:
                                        {{
                                          ticket.getGame[0].game_number
                                        }} </span
                                      ><br />
                                      <h3>Tickets:</h3>
                                      <span>
                                        {{ ticket.choosen_numbers }}
                                      </span>

                                      <barcode
                                        class=""
                                        :display-value="false"
                                        :value="ticket.user_id"
                                        height="35px"
                                      >
                                        Some thing went wrong.
                                      </barcode>
                                      <div class="text-right">
                                        <v-btn
                                          v-if="isAllowedToCopy == 1"
                                          text
                                          small
                                          class="blue--text"
                                          @click="
                                            getSpinCopy(
                                              ticket,
                                              ticket.getGame[0]
                                            )
                                          "
                                        >
                                          <span class="text-capitalize"
                                            >Copy</span
                                          >
                                        </v-btn>
                                      </div>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-form>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-dialog
                v-model="spinPrintDialog"
                persistent
                max-width="600"
                v-if="spinCurrentGame.length > 0 && brachData != ''"
              >
                <v-card class="">
                  <v-card-text
                    class=""
                    ref="spinPrintable"
                    v-if="brachData.ticket_layout == 'Layout 2'"
                    id="layoutWaterMark"
                    style="font-family: Arial"
                  >
                    <div style="margin-left: 0px; color: black">
                      <!-- {{ commonFeatures[0].brand_name }} K E N O -->

                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ spinUserNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.brachData.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              copyMessage == "copy"
                                ? new Date(Number(gameDateToCopy))
                                : new Date(Number(spinCurrentGame[0].game_date))
                                  | formatDate
                            }}

                            (UTC +3)/(#{{
                              copyMessage == "copy"
                                ? "Copy"
                                : $store.state.spinTicketNumber
                            }})
                          </span>
                        </div>
                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="brachData.logo != 'Normal' && brachData != ''"
                            :src="require(`../assets/${brachData.logo}.png`)"
                          />
                        </span>
                      </div>
                      <p
                        style="
                          clear: left;
                          float: left;
                          font-size: 11px;
                          text-align: left;
                        "
                      >
                        Spin And Win
                        {{ new Date(spinGameDate) | formatDate }} #{{
                          copyMessage == "copy"
                            ? gameNumberToCopy
                            : spinCurrentGame[0].game_number
                        }}
                      </p>
                      <!-- <hr style="border-top: 1px dashed" /> -->

                      <table
                        style="
                          width: 100%;
                          text-align: left;
                          padding-top: 0;
                          margin-top: 0;
                          font-size: 11px;
                        "
                      >
                        <tr>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            Choosen
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Odds
                          </th>
                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Stake
                          </th>

                          <th
                            style="
                              padding-bottom: 4px;
                              border-bottom: 1px solid grey;
                              font-weight: normal;
                            "
                          >
                            | &nbsp;&nbsp;Win
                          </th>
                        </tr>

                        <span
                          v-for="(selectedNumber, i) in selectedSpinNumbers"
                          :key="i + 1000"
                        >
                          <span>
                            <tr>
                              <td style="padding-top: 0px">
                                {{ selectedNumber }}
                              </td>
                              <td style="padding-top: 0px">
                                <!-- ====== -->
                                <span
                                  v-if="selectedNumber.includes('Neighbors')"
                                >
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].neighbors
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('&')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low_color
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Donzen')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].dozen
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Sector')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].sector
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Final')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].final
                                  }}
                                </span>
                                <span
                                  v-if="
                                    selectedNumber == 'Red' ||
                                    selectedNumber == 'Black' ||
                                    selectedNumber == 'High' ||
                                    selectedNumber == 'Low' ||
                                    selectedNumber.includes('Even') ||
                                    selectedNumber.includes('Odd')
                                  "
                                >
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Green')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number
                                  }}
                                </span>
                                <span
                                  v-if="
                                    selectedNumber.includes(',') &&
                                    selectedNumber.split(',').length == 2
                                  "
                                >
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number / 2
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Twins')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].twins
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('|')">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].mirror
                                  }}
                                </span>
                                <span v-if="!isNaN(selectedNumber)">
                                  | &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number
                                  }}
                                </span>
                              </td>
                              <td style="padding-top: 0px">
                                | &nbsp;&nbsp;{{ spinInputMoney[i] }}
                              </td>

                              <!-- ===== -->
                              <td style="padding-top: 0px">
                                <span
                                  v-if="selectedNumber.includes('Neighbors')"
                                >
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].neighbors
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('&')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].high_low_color
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Donzen')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].dozen
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Sector')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].sector
                                  }}
                                </span>
                                <span v-if="selectedNumber.includes('Final')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].final
                                  }}
                                </span>

                                <span
                                  v-if="
                                    selectedNumber == 'Red' ||
                                    selectedNumber == 'Black' ||
                                    selectedNumber == 'High' ||
                                    selectedNumber == 'Low' ||
                                    selectedNumber.includes('Even') ||
                                    selectedNumber.includes('Odd')
                                  "
                                >
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].high_low
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Green')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].number
                                  }}
                                </span>
                                <span
                                  v-if="
                                    selectedNumber.includes(',') &&
                                    selectedNumber.split(',').length == 2
                                  "
                                >
                                  | &nbsp;&nbsp;{{
                                    (parseFloat(spinInputMoney[i]) *
                                      $store.state.spinOdds[0].number) /
                                    2
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('Twins')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].twins
                                  }}
                                </span>

                                <span v-if="selectedNumber.includes('|')">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].mirror
                                  }}
                                </span>

                                <span v-if="!isNaN(selectedNumber)">
                                  | &nbsp;&nbsp;{{
                                    parseFloat(spinInputMoney[i]) *
                                    $store.state.spinOdds[0].number
                                  }}
                                </span>
                              </td>
                            </tr>
                          </span>
                        </span>
                      </table>
                      <!-- <div style="border-style: ridge">
                      <table style="width: 100%">
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: center;
                            "
                          >
                            {{ spinTotalInputMoney }}
                            <span style="font-weight: normal; font-size: 14px"
                              >Birr</span
                            >
                          </td>
                        </tr>

                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Possible Win
                          </td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="font-weight: bold; padding-top: 3px"
                          ></td>
                          <td
                            style="
                              font-weight: bold;                              padding-top: 3px;
                              text-align: center;
                            "
                          >
                            {{ spinTotalPossibleWin }}
                            <span style="font-weight: normal; font-size: 14px"
                              >Birr</span
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div
                    style="
                      text-align: center;
                      font-size: 11px;
                      margin-top: 2px;
                    "
                  >
                    Valid Until:
                    {{
                      new Date(spinGameDate + 48 * 60 * 60 * 1000)
                        | formatDate
                    }}
                  </div>
                  <div style="text-align: center">
                    <barcode :value="spinUserNumber" height="70px">
                      Some thing went wrong.
                    </barcode>
                  </div> -->

                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ spinTotalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table
                          style="width: 100%; font-size: 11px; width: 100%"
                        >
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ spinTotalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ spinTotalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(spinGameDate + 48 * 60 * 60 * 1000)
                          | formatDate
                      }}
                    </div>
                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="spinUserNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                spinUserNumber
                                  .toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-card-text
                    class=""
                    ref="spinPrintable"
                    v-if="brachData.ticket_layout == 'Layout 1'"
                    id="layoutWaterMark"
                    style="font-family: Arial"
                  >
                    <div style="margin-left: 0px; color: black">
                      <!-- {{ commonFeatures[0].brand_name }} K E N O -->

                      <div>
                        <div
                          style="
                            clear: right;
                            float: right;
                            font-size: 11px;
                            text-align: right;
                          "
                        >
                          <span>
                            {{ spinUserNumber }}
                          </span>
                          <br />
                          <span>
                            {{ this.brachData.shop_number }}
                          </span>
                          <br />
                          <span>
                            {{ this.$store.state.cashier.cashier_full_name }}
                          </span>
                          <br />

                          <span>
                            {{
                              copyMessage == "copy"
                                ? new Date(Number(gameDateToCopy))
                                : new Date(Number(spinCurrentGame[0].game_date))
                                  | formatDate
                            }}

                            (UTC +3)/(#{{
                              copyMessage == "copy"
                                ? "Copy"
                                : $store.state.spinTicketNumber
                            }})
                          </span>
                        </div>
                        <span style="font-weight: bold; margin-left: 0px">
                          <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                          <img
                            height="30"
                            width="60"
                            v-if="brachData.logo != 'Normal' && brachData != ''"
                            :src="require(`../assets/${brachData.logo}.png`)"
                          />
                        </span>
                      </div>

                      <!-- <hr style="border-top: 1px dashed" /> -->

                      <table
                        style="
                          width: 100%;
                          text-align: left;
                          padding-top: 0;
                          margin-top: 0;
                          font-size: 11px;
                        "
                      >
                        <span
                          v-for="(selectedNumber, i) in selectedSpinNumbers"
                          :key="i + 1000"
                        >
                          <span>
                            <tr>
                              <td style="padding-top: 4px; font-size: 11px">
                                <span
                                  v-if="selectedNumber.includes('Neighbors')"
                                >
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Neighbors</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp; &nbsp;
                                  {{
                                    selectedNumber.split("(")[1].split(")")[0]
                                  }}
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].neighbors
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('&')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >High/Low And Color</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low_color
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('Donzen')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Dozens</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].dozen
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('Sector')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Sector</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;
                                  <span v-if="selectedNumber == 'Sector A'"
                                    >32/15/19/4/21/2</span
                                  >
                                  <span v-if="selectedNumber == 'Sector B'"
                                    >25/17/34/6/27/13</span
                                  >
                                  <span v-if="selectedNumber == 'Sector C'"
                                    >36/11/30/8/23/10</span
                                  >
                                  <span v-if="selectedNumber == 'Sector D'"
                                    >5/24/16/33/1/20</span
                                  >
                                  <span v-if="selectedNumber == 'Sector E'"
                                    >14/31/9/22/18/29</span
                                  >
                                  <span v-if="selectedNumber == 'Sector F'"
                                    >7/28/12/35/3/26</span
                                  >
                                  &nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].sector
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('Final')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Final</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].final
                                  }}.00
                                </span>
                                <span
                                  v-if="
                                    selectedNumber == 'Red' ||
                                    selectedNumber == 'Black'
                                  "
                                >
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Color</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}.00
                                </span>
                                <span
                                  v-if="
                                    selectedNumber.includes('Even') ||
                                    selectedNumber.includes('Odd')
                                  "
                                >
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Even/Odd</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}.00
                                </span>

                                <span
                                  v-if="
                                    selectedNumber == 'High' ||
                                    selectedNumber == 'Low'
                                  "
                                >
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >High/Low</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].high_low
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('Green')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >0 Green</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number
                                  }}.00
                                </span>
                                <span
                                  v-if="
                                    selectedNumber.includes(',') &&
                                    selectedNumber.split(',').length == 2
                                  "
                                >
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number / 2
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('Twins')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Twins</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].twins
                                  }}.00
                                </span>

                                <span v-if="selectedNumber.includes('|')">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Mirror</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].mirror
                                  }}.00
                                </span>
                                <span v-if="!isNaN(selectedNumber)">
                                  <span
                                    style="font-weight: bold; font-size: 11px"
                                    >Win</span
                                  >
                                  <br />
                                  Spin And Win
                                  {{ new Date(spinGameDate) | formatDate }} #{{
                                    copyMessage == "copy"
                                      ? gameNumberToCopy
                                      : spinCurrentGame[0].game_number
                                  }}
                                  <br />
                                  &nbsp;&nbsp;{{ selectedNumber }}&nbsp;&nbsp;{{
                                    $store.state.spinOdds[0].number
                                  }}.00
                                </span>
                              </td>

                              <td
                                style="
                                  text-align: right;
                                  font-weight: bold;
                                  padding-top: 0px;
                                "
                              >
                                <span style="font-size: 11px">Br </span
                                >{{ spinInputMoney[i] }}.00 <span><br /></span
                                ><span
                                  ><br />
                                  <br
                                /></span>
                              </td>

                              <!-- ===== -->
                            </tr>
                          </span>
                        </span>
                      </table>
                      <!-- <div style="border-style: ridge">
                    <table style="width: 100%">
                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Total Stake
                        </td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: center;
                          "
                        >
                          {{ spinTotalInputMoney }}
                          <span style="font-weight: normal; font-size: 14px"
                            >Birr</span
                          >
                        </td>
                      </tr>

                      <tr>
                        <td style="font-weight: bold; padding-top: 3px">
                          Possible Win
                        </td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="font-weight: bold; padding-top: 3px"
                        ></td>
                        <td
                          style="
                            font-weight: bold;
                            padding-top: 3px;
                            text-align: center;
                          "
                        >
                          {{ spinTotalPossibleWin }}
                          <span style="font-weight: normal; font-size: 14px"
                            >Birr</span
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div
                  style="
                    text-align: center;
                    font-size: 11px;
                    margin-top: 2px;
                  "
                >
                  Valid Until:
                  {{
                    new Date(spinGameDate + 48 * 60 * 60 * 1000)
                      | formatDate
                  }}
                </div>
                <div style="text-align: center">
                  <barcode :value="spinUserNumber" height="70px">
                    Some thing went wrong.
                  </barcode>
                </div> -->

                      <table
                        style="width: 100%; font-size: 11px; margin-top: 5px"
                      >
                        <tr>
                          <td style="font-weight: bold; padding-top: 3px">
                            Total Stake
                          </td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td style="font-weight: bold; padding-top: 3px"></td>
                          <td
                            style="
                              font-weight: bold;
                              padding-top: 3px;
                              text-align: right;
                            "
                          >
                            &nbsp;&nbsp;&nbsp;
                            <span style="font-size: 11px">Br </span
                            >{{ spinTotalInputMoney.toFixed(2) }}
                          </td>
                        </tr>
                      </table>
                      <div
                        style="
                          border: 1px solid !important;
                          border-collapse: collapse !important;
                          border-radius: 2px !important;

                          overflow: hidden;
                        "
                      >
                        <table
                          style="width: 100%; font-size: 11px; width: 100%"
                        >
                          <tr>
                            <td style="font-weight: bold; padding-top: 0px">
                              Min Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ spinTotalInputMoney.toFixed(2) }}
                            </td>
                          </tr>

                          <tr style="padding-top: 0px">
                            <td style="font-weight: bold; padding-top: 0px">
                              Max Payout (Incl Stake)
                            </td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="font-weight: bold; padding-top: 0px"
                            ></td>
                            <td
                              style="
                                font-weight: bold;
                                padding-top: 0px;
                                text-align: right;
                              "
                            >
                              &nbsp;&nbsp;&nbsp;<span style="font-size: 11px"
                                >Br </span
                              >{{ spinTotalPossibleWin.toFixed(2) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      style="
                        text-align: center;
                        font-size: 10px;
                        padding-top: 3px;
                      "
                    >
                      Valid Until:
                      {{
                        new Date(spinGameDate + 48 * 60 * 60 * 1000)
                          | formatDate
                      }}
                    </div>
                    <table
                      style="font-size: 11px; text-align: center; width: 100%"
                    >
                      <tr>
                        <td
                          style="
                            padding-left: 3px;
                            padding-right: 3px;
                            text-align: center;
                          "
                        >
                          <div
                            style="
                              padding-left: 25px;

                              text-align: center;
                            "
                          >
                            <barcode
                              :display-value="false"
                              :value="spinUserNumber"
                              height="50px"
                              margin="0px"
                            >
                              Some thing went wrong.
                            </barcode>
                          </div>
                          <div style="text-align: center; padding-right: 20px">
                            <span
                              >*
                              {{
                                spinUserNumber
                                  .toString()
                                  .split("")
                                  .toString()
                                  .replaceAll(",", " &nbsp;&nbsp; ")
                              }}
                              *</span
                            >
                          </div>
                        </td>
                      </tr>
                    </table>

                    <div
                      v-if="commonFeatures.length > 0"
                      style="text-align: center"
                    >
                      <span
                        style="text-align: center; font-weight: bold"
                        v-if="commonFeatures.length > 0"
                      >
                        {{ commonFeatures[0].phone }}

                        {{ commonFeatures[0].others }}

                        {{ commonFeatures[0].brand_name }}
                      </span>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" class="text-capitalize" text>
                      Waiting...
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="spinPrintDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="spinIsPlaying"
                persistent
                max-width="420"
                class="red"
              >
                <p
                  class="headline white--text pink pa-5"
                  style="border-radius: 5px"
                >
                  Spin is in playing mode, please try later! <br />
                  <v-btn small text @click="whichToShow = 'keno'">
                    <span
                      class="mr-2 text-capitalize white--text text--darken-1"
                      >Switch to Keno</span
                    >
                  </v-btn>
                </p>
              </v-dialog>

              <v-dialog v-model="SpinVerifyDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Verify user </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="spinUserIDToVerify"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="spinVerifyLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="spinVerify"
                      >
                        verify
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          SpinVerifyDialog = false;
                          spinUserIDToVerify = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="spinReportTicketDialog"
                persistent
                max-width="420"
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Report ticket </v-card-title>
                  <v-form @submit.prevent="">
                    <v-card-text class="pl-5 pr-5">
                      <P
                        >Scan the Bar code or Enter user Id under the Bar
                        code</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="spinUserIdForReport"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        hide-details="true"
                        prepend-icon="view_column"
                      ></v-text-field>

                      <v-text-field
                        class="mt-3"
                        hide-details="true"
                        style="font-size: 11px"
                        v-model="spinReportReason"
                        :rules="[(v) => !!v || 'Required']"
                        label="Write a reason"
                        required
                        dense
                        outlined
                        prepend-icon="note"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="spinReportLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="spinReportTicket"
                      >
                        report
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="
                          spinReportTicketDialog = false;
                          spinUserIdForReport = '';
                        "
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog v-model="spinWinnersDialog" persistent max-width="420">
                <v-card class="text-center pt-5">
                  <v-card-title> Check ticket </v-card-title>

                  <v-card-text>
                    <P
                      class="green--text"
                      v-if="spinTotalWinMoneyForOneUser > 0"
                    >
                      <v-icon class="green--text">check</v-icon>
                      User won total

                      <strong
                        v-if="
                          spinVerifyUserData[0].getJackpotHistory.length > 0
                        "
                        >{{
                          spinTotalWinMoneyForOneUser +
                          spinVerifyUserData[0].getJackpotHistory[0].amount
                        }}</strong
                      >

                      <strong v-else>{{ spinTotalWinMoneyForOneUser }}</strong>
                      Birr <br /><br />
                      <p
                        v-if="
                          spinVerifyUserData[0].getJackpotHistory.length > 0
                        "
                        class="green--text"
                      >
                        {{
                          spinVerifyUserData[0].getJackpotHistory[0].jack_type
                        }}:
                        {{ spinVerifyUserData[0].getJackpotHistory[0].amount }}
                        Birr Won
                      </p>

                      <div v-for="(wonList, i) in payableList" :key="i">
                        <span
                          class="red--text"
                          v-if="wonList.includes(' Loss ')"
                        >
                          {{ wonList }}
                        </span>

                        <span v-else>
                          {{ wonList }}
                        </span>
                      </div>
                    </P>
                    <P class="red--text" v-else
                      ><v-icon class="red--text">cancel</v-icon> User loss the
                      game!
                    </P>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="spinPayLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      v-if="spinTotalWinMoneyForOneUser > 0"
                      @click="spinPayWinnerAward"
                    >
                      Pay
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="spinWinnersDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="spinCancelDialog"
                persistent
                max-width="420"
                top
              >
                <v-card class="text-center pt-5">
                  <v-card-title> Cancel Ticket </v-card-title>

                  <v-form @submit.prevent="">
                    <v-card-text>
                      <P
                        >Scan the Bar code or Enter user Id under the Bar to
                        cancel the ticket</P
                      >
                      <v-text-field
                        autofocus
                        style="font-size: 11px"
                        v-model="spinUserIDToCancel"
                        :rules="[(v) => !!v || 'Required']"
                        label="User ID or Bar code"
                        required
                        dense
                        outlined
                        prepend-icon="view_column"
                      ></v-text-field>

                      <P>Are you sure you want to cancel this ticket?</P>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :loading="spinCancelLoading"
                        color="green darken-1"
                        class="text-capitalize"
                        text
                        type="submit"
                        @click="normalSpinCancelTicket"
                      >
                        Yes
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        dark
                        color="red"
                        text
                        @click="spinCancelDialog = false"
                      >
                        No
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="neighboursPopUp"
                persistent
                max-width="420"
                top
              >
                <v-card class="text-center pt-5">
                  <v-card-title>
                    Choose Number <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="neighboursPopUp = false"
                    >
                      Close
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs2 v-for="(neighbors, i) in 37" :key="i">
                        <a @click="chooseNeighbor(i)">
                          <v-card
                            class="ma-1 mt-0 mb-2 pt-2 pb-2 customamount"
                            id="numbers"
                          >
                            <span>{{ i }}</span>
                          </v-card>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="twoNumbersPopUp"
                persistent
                max-width="420"
                top
              >
                <v-card class="text-center pt-5">
                  <v-card-title>
                    Choose two numbers <v-spacer></v-spacer>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="
                        twoNumbersPopUp = false;
                        twoNumbers = [];
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex v-if="twoNumbers.length > 0" xs12>
                        <h3 class="black--text mb-4">
                          {{ twoNumbers.toString() }}
                        </h3>
                      </v-flex>
                      <v-flex xs2 v-for="(neighbors, i) in 37" :key="i">
                        <a @click="chooseTwoNumbers(i)">
                          <v-card
                            class="grey lighten-2 black--text ma-1 mb-0 pt-2 pb-2 customamount"
                            id="numbers"
                          >
                            <h3>{{ i }}</h3>
                          </v-card>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize blue--text"
                      text
                      @click="saveTwoNumbers"
                    >
                      save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <v-dialog v-model="logoutDialog" persistent max-width="420">
              <v-card class="pt-5">
                <v-form @submit.prevent="">
                  <v-card-text
                    ref="printReport"
                    style="font-size: 12px; font-family: Arial"
                  >
                    <div>
                      <div
                        style="
                          clear: right;
                          float: right;
                          font-size: 12px;
                          text-align: right;
                        "
                      >
                        <br />
                        <span>
                          {{ this.brachData.shop_number }}
                        </span>
                        <br />
                        <span>
                          {{ this.$store.state.cashier.cashier_full_name }}
                        </span>
                      </div>

                      <span style="font-weight: bold; margin-left: 0px">
                        <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                        <img
                          height="40"
                          width="60"
                          v-if="brachData.logo != 'Normal' && brachData != ''"
                          :src="require(`../assets/${brachData.logo}.png`)"
                        />
                      </span>
                    </div>
                    <br />

                    <span>
                      <strong> Log in time:</strong> <br />
                      {{
                        new Date(Number($store.state.cashier.loggin_time))
                          | formatDate
                      }}
                    </span>
                    <br />
                    <span>
                      <strong>Log out time:</strong><br />
                      {{ new Date(Number(Date.now())) | formatDate }} </span
                    ><br />
                    <span>
                      <strong>Location:</strong> <br />
                      {{ currentLocation }}</span
                    ><br />
                    <span>
                      <strong>IP Address:</strong> <br />
                      {{ ipAddress }}</span
                    >
                    <br />
                    <hr style="margin-top: 2px; margin-bottom: 2px" />

                    <table style="width: 100%; font-size: 12px">
                      <tr>
                        <td>
                          <strong>Total Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noTickets + spinNoTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Paid Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noPaidTickets + spinNoPaidTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Cancelled Tickets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp;
                          {{ noCancelledTickets + spinNoCancelledTickets }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Number of Bets:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; {{ noBets + spinNoBets }}
                        </td>
                      </tr>
                      <tr>
                        <td style="margin-top: 2px; margin-bottom: 2px">
                          <hr />
                        </td>
                        <td style="margin-top: 2px; margin-bottom: 2px">
                          <hr />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{ totalMoneyOnHand + spinTotalMoneyOnHand }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total paid money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            totalMoneyPaid +
                            spinTotalMoneyPaid +
                            paidKenoMoneyFromYesterday +
                            paidSpinMoneyFromYesterday +
                            paidJackpotMoney
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total Jackpot paid money:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{ paidJackpotMoney }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total paid from yesterday:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            paidKenoMoneyFromYesterday +
                            paidSpinMoneyFromYesterday
                          }}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Total money on hand:</strong>
                        </td>
                        <td style="text-align: center">
                          | &nbsp; <span style="font-size: 14px">Br </span>
                          {{
                            totalMoneyOnHand -
                            totalMoneyPaid -
                            paidKenoMoneyFromYesterday +
                            (spinTotalMoneyOnHand -
                              spinTotalMoneyPaid -
                              paidSpinMoneyFromYesterday) -
                            paidJackpotMoney
                          }}
                        </td>
                      </tr>
                    </table>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      :loading="logoutLoading"
                      class="text-capitalize pink--text"
                      text
                      @click="logout"
                    >
                      print report
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="tmhPopUp" persistent max-width="420">
              <v-card class="pt-5">
                <v-card-title> Total Money on Hand </v-card-title>
                <v-form @submit.prevent="">
                  <v-card-text>
                    <P style="font-size: 18px"
                      >Total money:
                      {{ totalMoneyOnHand + spinTotalMoneyOnHand }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total paid money:
                      {{
                        totalMoneyPaid +
                        spinTotalMoneyPaid +
                        paidKenoMoneyFromYesterday +
                        paidSpinMoneyFromYesterday +
                        paidJackpotMoney
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total Jackpot paid money:
                      {{ paidJackpotMoney }}
                      <span style="font-size: 14px">ETB</span></P
                    >

                    <P style="font-size: 18px"
                      >Total money on hand:
                      {{
                        totalMoneyOnHand -
                        totalMoneyPaid -
                        paidKenoMoneyFromYesterday +
                        (spinTotalMoneyOnHand -
                          spinTotalMoneyPaid -
                          paidSpinMoneyFromYesterday) -
                        paidJackpotMoney
                      }}
                      <span style="font-size: 14px">ETB</span></P
                    >
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="tmhPopUp = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <v-dialog v-model="unpaidDialog" persistent max-width="600">
              <v-card class="pt-5">
                <v-card-title>
                  Tickets <v-spacer></v-spacer>

                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="unpaidDialog = false"
                  >
                    Cancel
                  </v-btn></v-card-title
                >

                <v-card-text>
                  <v-layout row wrap class="text-center">
                    <v-flex
                      xs6
                      class="pa-2"
                      style="border-right: 1px solid grey"
                    >
                      <h3 class="black--text text-center">Keno ticket#</h3>
                      <br />

                      <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Paid = {{ kenoPaidList.length }}</u> </span
                          ><br />
                          <span v-for="keno in kenoPaidList" :key="keno"
                            >#{{ keno }} <br />
                          </span>
                        </v-flex>

                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Unpaid = {{ kenoUnpaidList.length }}</u> </span
                          ><br />
                          <span v-for="keno in kenoUnpaidList" :key="keno"
                            >#{{ keno }} <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 class="pa-2">
                      <h3 class="black--text text-center">Spin ticket#</h3>
                      <br />

                      <v-layout row wrap>
                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Paid = {{ spinPaidList.length }}</u> </span
                          ><br />
                          <span v-for="spin in spinPaidList" :key="spin"
                            >#{{ spin }} <br />
                          </span>
                        </v-flex>

                        <v-flex xs6 class="pa-2">
                          <span class="black--text text-center">
                            <u>Unpaid = {{ spinUnpaidList.length }}</u> </span
                          ><br />
                          <span v-for="spin in spinUnpaidList" :key="spin"
                            >#{{ spin }} <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="last20GameDialog"
              persistent
              max-width="700"
              v-if="lastTwentyGames.length > 0"
            >
              <v-card class="">
                <v-card-text
                  class=""
                  ref="lastGamePrintable"
                  style="font-family: Arial"
                >
                  <div>
                    <div
                      style="
                        clear: right;
                        float: right;
                        font-size: 12px;
                        text-align: right;
                      "
                    >
                      <br />
                      <span>
                        {{ this.brachData.shop_number }}
                      </span>
                      <br />
                      <span>
                        {{ this.$store.state.cashier.cashier_full_name }}
                      </span>
                      <br />

                      <span>
                        {{ new Date(Date.now()) | formatDate }}

                        (UTC +3)
                      </span>
                    </div>

                    <span style="font-weight: bold; margin-left: 0px">
                      <!-- {{ commonFeatures[0].brand_name }} K E N O -->
                      <img
                        height="40"
                        width="60"
                        v-if="brachData.logo != 'Normal' && brachData != ''"
                        :src="require(`../assets/${brachData.logo}.png`)"
                      />
                    </span>
                  </div>

                  <div
                    style="
                      text-align: center;
                      margin-bottom: 2px;
                      font-size: 14px;
                    "
                  >
                    <br /><br />
                    <span style="font-weight: bold; font-size: 16px">
                      Last 20 Draw
                    </span>

                    <hr />
                    <span
                      v-if="
                        lastTwentyGames[0].drawn_numbers.split(',').length > 2
                      "
                    >
                      Game: Keno
                    </span>
                    <span v-else> Game: Spin And Win </span>
                    <br />
                    Draw: ({{ lastTwentyGames[0].game_number }} -
                    {{
                      lastTwentyGames[lastTwentyGames.length - 1].game_number
                    }})
                  </div>

                  <div style="text-align: left; font-size: 12px">
                    <br />

                    <span v-for="(game, i) in lastTwentyGames" :key="i">
                      #{{ game.game_number }}: {{ game.drawn_numbers }}
                      <span v-if="game.drawn_numbers.split(',').length > 2">
                        <br
                      /></span>
                      <span v-else>
                        <span v-if="(i + 1) % 4 != 0">&nbsp;&nbsp;</span>
                      </span>
                    </span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="printLastgame"
                    color="green darken-1"
                    class="text-capitalize"
                    text
                  >
                    Print
                  </v-btn>
                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="last20GameDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="helpDialog" persistent fullscreen>
              <v-card class="pt-5">
                <v-card-title>
                  Help <v-spacer></v-spacer>

                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="
                      helpDialog = false;
                      wichVideoToPlay = '';
                    "
                  >
                    Cancel
                  </v-btn></v-card-title
                >

                <v-card-text>
                  <v-layout
                    row
                    wrap
                    class="mb-15 pa-1 pt-0"
                    style="font-size: 14px"
                    justify-space-around
                  >
                    <v-flex xs12 class="pt-3">
                      <v-textarea
                        v-model="helpText"
                        :rules="[(v) => !!v || 'Required']"
                        auto-grow
                        outlined
                        rows="4"
                        row-height="15"
                        disabled
                      ></v-textarea>
                      <!-- <v-text-field
                  style="font-size: 11px"
                  v-model="helpText"
                  :rules="[(v) => !!v || 'Required']"
                  label="Write Help"
                  required
                  dense
                  outlined
                  append-icon="search"
                ></v-text-field> -->
                    </v-flex>
                    <v-flex
                      xs12
                      class="text-center"
                      v-for="(allVideo, i) in video"
                      :key="i"
                    >
                      <video
                        v-if="allVideo != '' && wichVideoToPlay == allVideo"
                        playsinline
                        style="pointer-events: none"
                        id="video"
                        width="100%"
                        autoplay
                        :src="allVideo"
                      ></video>
                      <a
                        @click="wichVideoToPlay = allVideo"
                        class="mr-3"
                        v-if="allVideo != ''"
                      >
                        Play Video {{ i }}</a
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <div class="hidden-lg-only text-center white--text">
            <p>Not supported in small screens, please open in pc</p>
          </div>
        </div>
      </div>
      <div v-else class="mt-16">
        <p v-if="partnerBalance != ''" class="headline red--text text-center">
          Reachs maximum day, Please contact the admin
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";

import axios from "axios";

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      isLoad: false,
      helpDialog: false,
      helpText: "",
      help: [],
      wichVideoToPlay: "",
      video: "",
      timePercent: 0,
      timeInSeconds: 0,
      minutes: 3,
      seconds: 56,
      selectedNumbers: [],
      selectedNumberOptionCounter: 0,
      userIDToVerify: "",
      userIDToCancel: "",
      isDuplicated: false,
      printDialog: false,
      verifyDialog: false,
      verifyLoading: false,
      winnersDialog: false,
      cancelDialog: false,
      payLoading: false,
      cancelLoading: false,
      reportTicketDialog: false,
      userIdForReport: "",
      reportReason: "",
      gameDate: "",
      userNumber: "",
      reportLoading: false,
      currentGame: [],
      noTickets: 0,
      noPaidTickets: 0,
      noCancelledTickets: 0,
      noBets: 0,
      verifyUserData: [],
      selectedEvenOddAndHighLow: [],
      isPlaying: false,
      totalWinMoneyForOneUser: 0,
      evenOddInputMoney: [],
      numberInputMoney: [],
      totalMoneyOnHand: 0,
      totalMoneyPaid: 0,
      selectedCustomAmount: 0,
      totalInputMoney: 0,
      totalPossibleWin: 0,
      lastTwoGames: [],
      paidJackpotMoney: 0,

      //spin
      neighboursPopUp: false,
      twoNumbersPopUp: false,
      spinTimePercent: 0,
      spinTimeInSeconds: 0,
      spinMinutes: 4,
      spinSeconds: 30,
      selectedSpinNumbers: [],
      spinUserIDToVerify: "",
      spinUserIDToCancel: "",
      spinIsDuplicated: false,
      spinPrintDialog: false,
      SpinVerifyDialog: false,
      spinVerifyLoading: false,
      spinWinnersDialog: false,
      spinCancelDialog: false,
      spinPayLoading: false,
      spinCancelLoading: false,
      spinReportTicketDialog: false,
      spinUserIdForReport: "",
      spinReportReason: "",
      spinGameDate: "",
      spinUserNumber: "",
      spinReportLoading: false,
      spinCurrentGame: [],
      spinNoTickets: 0,
      spinNoPaidTickets: 0,
      spinNoCancelledTickets: 0,
      spinNoBets: 0,
      spinVerifyUserData: [],
      spinIsPlaying: false,
      spinTotalWinMoneyForOneUser: 0,
      spinInputMoney: [],
      spinTotalMoneyOnHand: 0,
      spinTotalMoneyPaid: 0,
      spinLastTwoGames: [],
      spinNumbers: [],
      brachData: "",
      randomGame: [
        "Red",
        "Black",
        "Even",
        "Odd",
        "High",
        "Low",
        "Twins",
        "0 Green",
        "12|21",
        "13|31",
        "23|32",
        "High & Red",
        "Low & Red",
        "High & Black",
        "Low & Black",
        "Numbers",
        "1st Donzen",
        "2nd Donzen",
        "3rd Donzen",
        "Neighbours",
        "Sector A",
        "Sector B",
        "Sector C",
        "Sector D",
        "Sector E",
        "Sector F",
        "Final 0",
        "Final 1",
        "Final 2",
        "Final 3",
        "Final 4",
        "Final 5",
        "Final 6",
      ],
      spinSelectedCustomAmount: 0,

      spinTotalInputMoney: 0,
      spinTotalPossibleWin: 0,
      twoNumbers: [],
      //common
      partnerBalance: "",
      logoutLoading: false,
      logoutDialog: false,
      commonFeatures: [],
      tmhPopUp: false,
      customAmount: [250, 200, 150, 100, 50, 40, 30, 25, 20, 15, 10],
      currentLocation: "",
      ipAddress: "",
      rules: [(value) => !!value || "Required."],
      whichToShow: "keno",
      payableList: [],
      paidSpinMoneyFromYesterday: 0,
      paidKenoMoneyFromYesterday: 0,
      unpaidLoading: false,
      kenoUnpaidList: [],
      spinUnpaidList: [],

      kenoPaidList: [],
      spinPaidList: [],
      todaysWork: [],
      spinTodaysWork: [],
      unpaidDialog: false,
      kenoCurrentMousePosition: "",
      spinCurrentMousePosition: "",
      kenoevenoddCurrentMousePosition: "",
      inWhichToAddAmount: "",
      day_block: "",
      gameLoading: false,
      last20GameDialog: false,
      lastTwentyGames: [],
      gameNumberToCopy: "",
      gameDateToCopy: "",
      copyMessage: "",
      isAllowedToCopy: "",
    };
  },

  methods: {
    assignSelectedAmount(amount) {
      this.selectedCustomAmount = amount;
      if (
        this.kenoCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "number"
      )
        this.numberInputMoney[this.kenoCurrentMousePosition] =
          this.selectedCustomAmount;
      if (
        this.kenoevenoddCurrentMousePosition >= 0 &&
        this.inWhichToAddAmount == "evenodd"
      )
        this.evenOddInputMoney[this.kenoevenoddCurrentMousePosition] =
          this.selectedCustomAmount;
    },

    applyToAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        this.numberInputMoney.push(this.selectedCustomAmount);
      }

      for (let i = 0; i < this.selectedEvenOddAndHighLow.length; i++) {
        this.evenOddInputMoney.push(this.selectedCustomAmount);
      }
    },
    clearAll() {
      this.numberInputMoney = [];
      this.evenOddInputMoney = [];
      this.selectedCustomAmount = 0;
    },
    selectNumber(number) {
      if (this.selectedNumbers.length == 0) this.selectedNumbers.push([]);
      if (
        this.selectedNumbers[this.selectedNumberOptionCounter].indexOf(
          number
        ) != -1
      ) {
        this.selectedNumbers[this.selectedNumberOptionCounter].splice(
          this.selectedNumbers[this.selectedNumberOptionCounter].indexOf(
            number
          ),
          1
        );
      } else if (
        this.selectedNumbers[this.selectedNumberOptionCounter].length < 10
      )
        this.selectedNumbers[this.selectedNumberOptionCounter].push(number);
      this.kenoCurrentMousePosition = this.selectedNumbers.length - 1;
      this.inWhichToAddAmount = "number";
    },

    selectEvenOddAndHighLow(evenOddHighLow) {
      this.selectedEvenOddAndHighLow.push(evenOddHighLow);
      this.kenoevenoddCurrentMousePosition =
        this.selectedEvenOddAndHighLow.length - 1;
      this.inWhichToAddAmount = "evenodd";
    },

    removeEvenOddKenoList(item) {
      let index = this.selectedEvenOddAndHighLow.indexOf(item);
      this.selectedEvenOddAndHighLow.splice(index, 1);
      this.evenOddInputMoney.splice(index, 1);
      this.kenoevenoddCurrentMousePosition =
        this.selectedEvenOddAndHighLow.length - 1;
    },
    removeSelectedNumbers(index) {
      this.selectedNumbers.splice(index, 1);
      this.numberInputMoney.splice(index, 1);
      if (this.selectedNumberOptionCounter > 0)
        this.selectedNumberOptionCounter--;
      this.kenoCurrentMousePosition = this.selectedNumbers.length - 1;
    },

    saveTwoNumbers() {
      if (this.twoNumbers.length > 0)
        this.selectedSpinNumbers.push(this.twoNumbers.toString());
      this.twoNumbers = [];
      this.twoNumbersPopUp = false;
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },
    async getHelp() {
      try {
        var helpResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
              getCashierHelp {
                      help_tbl_id
                      help_text
                      video
                    
                    }
              
              }
              `,
          },
        });

        this.help = helpResult.data.data.getCashierHelp;
        this.helpText = this.help[0].help_text;
        this.video = this.help[0].video.split(",");
      } catch (err) {
        alert(err);
      }
    },

    async getUnpaid() {
      this.unpaidLoading = true;

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getDailyWorksByCashierId(date1:"${date1}", date2:"${date2}",   cashier_id:${this.$store.state.cashier.cashier_id}){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });
        this.todaysWork = todayResult.data.data.getDailyWorksByCashierId;

        var rowOut2 = this.$store.state.odds;
        this.kenoUnpaidList = [];
        this.kenoPaidList = [];

        for (let t = 0; t < this.todaysWork.length; t++) {
          var winnersTicketCounter = 0;
          var unpaidWinnersMoney = 0;
          if (this.todaysWork[t].is_cancelled == 0) {
            var tickets = this.todaysWork[t].choosen_numbers.split(":");
            var others = this.todaysWork[t].others.split(":");
            var drownNumbers =
              this.todaysWork[t].getGameForShifts[0].drawn_numbers != ""
                ? this.todaysWork[t].getGameForShifts[0].drawn_numbers.split(
                    ","
                  )
                : [];
            var evenOdd = this.todaysWork[t].getGameForShifts[0].even_odd;
            var highLow = this.todaysWork[t].getGameForShifts[0].high_low;

            if (tickets[0] != "") {
              for (let j = 0; j < tickets.length; j++) {
                var eachNumber = tickets[j].split("*")[0].split(",");
                var eachAmount = parseFloat(tickets[j].split("*")[1]);

                var matchCount = 0;
                for (let k = 0; k < eachNumber.length; k++) {
                  for (let m = 0; m < drownNumbers.length; m++) {
                    if (drownNumbers[m] == eachNumber[k].trim()) {
                      matchCount++;
                    }
                  }
                }

                for (let x = 0; x < rowOut2.length; x++) {
                  if (
                    parseInt(rowOut2[x].choosen_length) == eachNumber.length
                  ) {
                    var hits = rowOut2[x].hits.split(",");
                    var possibleWinMoney = rowOut2[x].odd_value.split(",");

                    for (let y = 0; y < hits.length; y++) {
                      if (matchCount == parseInt(hits[y].trim())) {
                        winnersTicketCounter++;
                        unpaidWinnersMoney =
                          unpaidWinnersMoney +
                          (parseFloat(possibleWinMoney[y].trim()) / 10) *
                            eachAmount;
                      }
                    }
                  }
                }
              }
            }

            if (others[0] != "") {
              for (let r = 0; r < others.length; r++) {
                if (
                  evenOdd.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      evenOdd.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }

                if (
                  highLow.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      highLow.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }
              }
            }
          }
          if (
            winnersTicketCounter != 0 &&
            this.todaysWork[t].winner_award == 0
          ) {
            this.kenoUnpaidList.push(
              this.todaysWork[t].ticket_number +
                " = " +
                unpaidWinnersMoney +
                " Birr"
            );
          }

          if (
            winnersTicketCounter != 0 &&
            this.todaysWork[t].winner_award != 0
          ) {
            this.kenoPaidList.push(this.todaysWork[t].ticket_number);
          }
        }
      } catch (err) {
        alert(err);
      }

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
              getDailyWorksByCashierId(date1:"${date1}", date2:"${date2}",   cashier_id:${this.$store.state.cashier.cashier_id}){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                        getSpinNumber{
                            spin_number_id
                            number
                            color
                            mirror
                            twins
                            final
                            dozen
                            sector
                            combination
                            even_odd
                            high_low
                            neighbors
                          }
                     }

                   }
              }
              `,
          },
        });
        this.spinTodaysWork = todayResult.data.data.getDailyWorksByCashierId;

        var rowOut2 = this.$store.state.spinOdds;

        this.spinUnpaidList = [];
        this.spinPaidList = [];

        for (let t = 0; t < this.spinTodaysWork.length; t++) {
          var winnersTicketCounter = 0;
          var unpaidWinnersMoney = 0;
          if (this.spinTodaysWork[t].is_cancelled == 0) {
            var tickets = this.spinTodaysWork[t].choosen_numbers.split(":");
            var spinNum =
              this.spinTodaysWork[t].getGameForShifts[0].getSpinNumber;

            if (tickets[0] != "") {
              this.spinNoBets = this.spinNoBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                var eachNumber = tickets[j].split("*")[0].trim();
                var eachAmount = parseFloat(tickets[j].split("*")[1]);

                if (spinNum.length > 0) {
                  if (spinNum[0].number.toString() == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].number * eachAmount;
                  } else if (eachNumber.includes(",")) {
                    if (
                      eachNumber.split(",")[0].trim() ==
                        spinNum[0].number.toString() ||
                      eachNumber.split(",")[1].trim() ==
                        spinNum[0].number.toString()
                    ) {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney +
                        (rowOut2[0].number / 2) * eachAmount;
                    }
                  } else if (spinNum[0].color == eachNumber) {
                    if (eachNumber == "0 Green") {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney + rowOut2[0].number * eachAmount;
                    } else {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney + rowOut2[0].high_low * eachAmount;
                    }
                  } else if (spinNum[0].sector == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].sector * eachAmount;
                  } else if (spinNum[0].even_odd == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].even_odd * eachAmount;
                  } else if (spinNum[0].high_low == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].high_low * eachAmount;
                  } else if (
                    spinNum[0].neighbors.split(",")[0].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[1].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[2].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[3].trim() == eachNumber ||
                    spinNum[0].neighbors.split(",")[4].trim() == eachNumber
                  ) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].neighbors * eachAmount;
                  } else if (spinNum[0].twins == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].twins * eachAmount;
                  } else if (spinNum[0].dozen == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].dozen * eachAmount;
                  } else if (eachNumber.includes("Final")) {
                    if (spinNum[0].final.includes(eachNumber)) {
                      winnersTicketCounter++;

                      unpaidWinnersMoney =
                        unpaidWinnersMoney + rowOut2[0].final * eachAmount;
                    }
                  } else if (spinNum[0].mirror == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney + rowOut2[0].mirror * eachAmount;
                  } else if (spinNum[0].combination == eachNumber) {
                    winnersTicketCounter++;

                    unpaidWinnersMoney =
                      unpaidWinnersMoney +
                      rowOut2[0].high_low_color * eachAmount;
                  }
                }
              }
            }
          }
          if (
            winnersTicketCounter != 0 &&
            this.spinTodaysWork[t].winner_award == 0
          )
            this.spinUnpaidList.push(
              this.spinTodaysWork[t].ticket_number +
                " = " +
                unpaidWinnersMoney +
                " Birr"
            );

          if (
            winnersTicketCounter != 0 &&
            this.spinTodaysWork[t].winner_award != 0
          )
            this.spinPaidList.push(this.spinTodaysWork[t].ticket_number);
        }
      } catch (err) {}
      this.unpaidDialog = true;
      this.unpaidLoading = false;
    },

    async getLastTwentyGames(game) {
      this.lastTwentyGames = [];
      this.gameLoading = true;

      if (game == "keno") {
        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
                getLastTwentyGames{
                  game_number
                  game_id
                  drawn_numbers
                }
  
                }
                `,
            },
          });

          this.lastTwentyGames = gameResult.data.data.getLastTwentyGames;
        } catch (err) {}
      } else {
        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `{
                getLastTwentyGames{
                  game_number
                  game_id
                  drawn_numbers
                }
  
                }
                `,
            },
          });

          this.lastTwentyGames = gameResult.data.data.getLastTwentyGames;
        } catch (err) {}
      }
      if (this.lastTwentyGames.length > 0) this.last20GameDialog = true;

      this.gameLoading = false;
    },

    async printLastgame() {
      var content = this.$refs.lastGamePrintable;
      this.last20GameDialog = false;

      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 100);
    },

    async recietOverview(id) {
      if (Number(Date.now() - this.$store.state.sessionTime) > 20 * 60 * 1000) {
        this.$store.dispatch("loginCashier", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      this.copyMessage = "";
      let count = 0;
      let totalMoney = 0;
      this.totalPossibleWin = 0;
      if (
        this.selectedNumbers.length == this.numberInputMoney.length &&
        this.selectedEvenOddAndHighLow.length == this.evenOddInputMoney.length
      ) {
        for (let v = 0; v < this.numberInputMoney.length; v++) {
          if (this.numberInputMoney[v] < 10 || this.numberInputMoney[v] > 2000)
            count++;
        }
        for (let v = 0; v < this.evenOddInputMoney.length; v++) {
          if (
            this.evenOddInputMoney[v] < 10 ||
            this.evenOddInputMoney[v] > 2000
          )
            count++;
        }
        for (let i = 0; i < this.selectedNumbers.length; i++) {
          if (this.selectedNumbers[i].length == 0) count++;
          if (this.numberInputMoney[i] == "") count++;
        }
        if (count == 0) {
          this.userNumber = Date.now();
          this.gameDate = Date.now();
          var choosenNumbers = "";
          var othersSelected = "";
          if (
            this.formatDate(Date.now()) !=
            this.$store.state.kenoTicketNumberDate
          ) {
            this.$store.dispatch(
              "kenoTicketNumberDate",
              this.formatDate(Date.now())
            );

            this.$store.dispatch("kenoTicketNumber", 0);
          }
          this.$store.dispatch(
            "kenoTicketNumber",
            this.$store.state.kenoTicketNumber + 1
          );
          for (let i = 0; i < this.selectedNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);
            for (let j = 0; j < this.$store.state.odds.length; j++) {
              if (
                this.$store.state.odds[j].choosen_length ==
                this.selectedNumbers[i].length
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  (parseFloat(this.numberInputMoney[i]) *
                    parseFloat(
                      this.$store.state.odds[j].odd_value.split(",")[0]
                    )) /
                    10;
              }
            }

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
          }

          for (let j = 0; j < this.selectedEvenOddAndHighLow.length; j++) {
            totalMoney = totalMoney + parseFloat(this.evenOddInputMoney[j]);
            for (let k = 0; k < this.$store.state.odds.length; k++) {
              if (
                this.$store.state.odds[k].choosen_length.toLowerCase() ==
                this.selectedEvenOddAndHighLow[j].toLowerCase()
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  parseFloat(this.evenOddInputMoney[j]) *
                    parseFloat(this.$store.state.odds[k].odd_value);
              }
            }

            if (othersSelected == "") {
              othersSelected =
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
            } else
              othersSelected =
                othersSelected +
                ":" +
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
          }

          if (this.partnerBalance.tempo_package >= totalMoney) {
            this.totalInputMoney = totalMoney;
            this.printDialog = true;

            try {
              var ticketResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               saveTicket(
                game_id:${this.currentGame[0].game_id},
                ticket_number:${this.$store.state.kenoTicketNumber},
               user_id:"${this.userNumber}",
               cashier_id:${this.$store.state.cashier.cashier_id},
               partner_id:${this.$store.state.cashier.partner_id},
               branch_id:${this.$store.state.cashier.branch_id},
               choosen_numbers: "${choosenNumbers}",
               others: "${othersSelected}",
               ticket_date: "${this.gameDate}",
               replaced_user_id: "${id}",
               tempo_package:${this.partnerBalance.tempo_package - totalMoney}

               )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printable;

                var newWindow = window.open("");

                newWindow.document.write(content.outerHTML);
                setTimeout(function () {
                  newWindow.print();
                  newWindow.close();
                }, 140);
                this.printDialog = false;

                await this.getBalance();
                await this.getLastTwoGames();
                this.selectedNumbers = [];
                this.selectedEvenOddAndHighLow = [];
                this.evenOddInputMoney = [];
                this.selectedNumberOptionCounter = 0;
                this.numberInputMoney = [];
              } else alert("Ticket not printed, try again");
            } catch (err) {
              alert(err);
            }
          } else alert("Insufficient funds, please deposit to continue");
        } else
          alert(
            "Please fill all fields, amounts, And make sure the amount is between 10 to 2000 Birr"
          );
      } else
        alert(
          "Please fill all fields, amounts, And make sure the amount is between 10 to 1000"
        );
    },

    async copyOverview() {
      this.copyMessage = "copy";
      let count = 0;
      let totalMoney = 0;
      this.totalPossibleWin = 0;
      if (
        this.selectedNumbers.length == this.numberInputMoney.length &&
        this.selectedEvenOddAndHighLow.length == this.evenOddInputMoney.length
      ) {
        for (let v = 0; v < this.numberInputMoney.length; v++) {
          if (this.numberInputMoney[v] < 10 || this.numberInputMoney[v] > 2000)
            count++;
        }
        for (let v = 0; v < this.evenOddInputMoney.length; v++) {
          if (
            this.evenOddInputMoney[v] < 10 ||
            this.evenOddInputMoney[v] > 2000
          )
            count++;
        }
        for (let i = 0; i < this.selectedNumbers.length; i++) {
          if (this.selectedNumbers[i].length == 0) count++;
          if (this.numberInputMoney[i] == "") count++;
        }
        if (count == 0) {
          var choosenNumbers = "";
          var othersSelected = "";

          for (let i = 0; i < this.selectedNumbers.length; i++) {
            totalMoney = totalMoney + parseFloat(this.numberInputMoney[i]);
            for (let j = 0; j < this.$store.state.odds.length; j++) {
              if (
                this.$store.state.odds[j].choosen_length ==
                this.selectedNumbers[i].length
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  (parseFloat(this.numberInputMoney[i]) *
                    parseFloat(
                      this.$store.state.odds[j].odd_value.split(",")[0]
                    )) /
                    10;
              }
            }

            if (choosenNumbers == "") {
              choosenNumbers =
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
            } else
              choosenNumbers =
                choosenNumbers +
                ":" +
                this.selectedNumbers[i].toString() +
                "*" +
                this.numberInputMoney[i].toString();
          }

          for (let j = 0; j < this.selectedEvenOddAndHighLow.length; j++) {
            totalMoney = totalMoney + parseFloat(this.evenOddInputMoney[j]);
            for (let k = 0; k < this.$store.state.odds.length; k++) {
              if (
                this.$store.state.odds[k].choosen_length.toLowerCase() ==
                this.selectedEvenOddAndHighLow[j].toLowerCase()
              ) {
                this.totalPossibleWin =
                  this.totalPossibleWin +
                  parseFloat(this.evenOddInputMoney[j]) *
                    parseFloat(this.$store.state.odds[k].odd_value);
              }
            }

            if (othersSelected == "") {
              othersSelected =
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
            } else
              othersSelected =
                othersSelected +
                ":" +
                this.selectedEvenOddAndHighLow[j] +
                "*" +
                this.evenOddInputMoney[j].toString();
          }

          this.totalInputMoney = totalMoney;
          this.printDialog = true;

          setTimeout(
            function () {
              this.printCopy();
            }.bind(this),
            140
          );
        } else
          alert(
            "Please fill all fields, amounts, And make sure the amount is between 10 to 2000 Birr"
          );
      } else
        alert(
          "Please fill all fields, amounts, And make sure the amount is between 10 to 1000"
        );
    },

    printCopy() {
      try {
        var content = this.$refs.printable;

        var newWindow = window.open("");

        newWindow.document.write(content.outerHTML);
        setTimeout(function () {
          newWindow.print();
          newWindow.close();
        }, 140);
        this.printDialog = false;

        this.selectedNumbers = [];
        this.selectedEvenOddAndHighLow = [];
        this.evenOddInputMoney = [];
        this.selectedNumberOptionCounter = 0;
        this.numberInputMoney = [];
        this.copyMessage = "";
      } catch (err) {
        alert(err);
      }
    },
    async getCurrentGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status

                   }
              }
              `,
          },
        });
        this.currentGame = currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.currentGame.length > 0) {
          this.timeInSeconds = Math.floor(
            236 - (Date.now() - Number(this.currentGame[0].game_date)) / 1000
          );

          this.timePercent =
            0.4238 *
            Math.floor(
              (Date.now() - Number(this.currentGame[0].game_date)) / 1000
            );
        } else {
          this.timePercent = 0;
          this.minutes = "00";
          this.seconds = "00";
        }
      } catch (err) {}
    },

    async getLastTwoGames() {
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }

                       getLastTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                         getGame{
                          game_id
                          game_number
                           }
                       }
                     }


              }
              `,
          },
        });

        this.lastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.lastTwoGames.length > 0) {
          var lastGame = this.lastTwoGames[0].getTickets;
          this.isDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.isDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.isDuplicated = true;
                }
              }
            }
          }
        }
      } catch (err) {}
    },

    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.$store.dispatch("odds", oddsResult.data.data.getOdds);
      } catch (err) {}
    },

    async getCommonFeatures() {
      try {
        var commonResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getCommonFeatures{
                 common_features_id
                 brand_name
                 phone
                 others
                }


              }
              `,
          },
        });

        this.commonFeatures = commonResult.data.data.getCommonFeatures;
      } catch (err) {
        alert(err);
      }
    },

    async reportTicket() {
      if (this.userIdForReport != "" && this.reportReason != "") {
        this.reportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.userIdForReport}",
              reason: "${this.reportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.reportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.reportLoading = false;
      } else alert("Please fill the fields");
    },

    async getBlockedCashier() {
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getBlockedCashier(cashier_id:${this.$store.state.cashier.cashier_id}){
                  is_active
                  is_allowed_anywhere
                }

              }
              `,
          },
        });

        this.isAllowedToCopy =
          cashierResult.data.data.getBlockedCashier[0].is_allowed_anywhere;

        if (!cashierResult.data.data.getBlockedCashier[0].is_active) {
          await this.logout();
          alert("Cashier is blocked");
        }
      } catch (err) {}
    },

    async payWinnerAward() {
      this.payLoading = true;
      try {
        var payResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               payWinnerAward(ticket_id:${this.verifyUserData[0].ticket_id},
               payer_cashier_id: ${this.$store.state.cashier.cashier_id},
               partner_id: ${this.$store.state.cashier.partner_id},
               user_id:"${this.verifyUserData[0].user_id}",
                winner_award: ${this.totalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
          },
        });

        if (
          payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.verifyUserData[0].getJackpotHistory.length > 0) {
            await axios({
              method: "POST",
              url: this.$store.state.cashierURL,

              data: {
                query: `mutation{
              editJackpotHistory(jackpot_history_id:${this.verifyUserData[0].getJackpotHistory[0].jackpot_history_id},
              cashier_id: ${this.$store.state.cashier.cashier_id},
              partner_id: ${this.$store.state.cashier.partner_id},
           ){
                      statusMessage

                   }
              }
              `,
              },
            });
          }

          if (
            Number(this.verifyUserData[0].ticket_date) <
            new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.cashierURL,

              data: {
                query: `mutation{
                   addPaidFromYesterday(cashier_id:${this.$store.state.cashier.cashier_id},
                   user_id:"${this.verifyUserData[0].user_id}",
                    winner_award: ${this.totalWinMoneyForOneUser}){
                          statusMessage
    
                       }
                  }
                  `,
              },
            });
          }
          this.winnersDialog = false;
          this.verifyDialog = true;
          await this.getBalance();
          this.userIDToVerify = "";
        }
      } catch (err) {
        alert(err);
      }

      this.payLoading = false;
    },

    async printAgain(ticket) {
      this.copyMessage = "";
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedNumbers.push(numbers[i].split("*")[0].split(","));
          this.numberInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }
      this.userIDToCancel = ticket.user_id;

      await this.recietOverview(ticket.user_id);
      await this.cancelTicket();
    },

    async getCopy(ticket, gameNumber) {
      this.copyMessage = "copy";
      var numbers = ticket.choosen_numbers.split(":");
      var others = ticket.others.split(":");
      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedNumbers.push(numbers[i].split("*")[0].split(","));
          this.numberInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      if (others[0] != "") {
        for (let j = 0; j < others.length; j++) {
          this.selectedEvenOddAndHighLow.push(others[j].split("*")[0].trim());
          this.evenOddInputMoney.push(others[j].split("*")[1].trim());
        }
      }

      this.userNumber = ticket.user_id;
      this.gameDate = ticket.ticket_date;
      this.gameNumberToCopy = gameNumber.game_number;
      this.gameDateToCopy = gameNumber.game_date;
      await this.copyOverview();
    },

    async spinPrintAgain(ticket) {
      this.copyMessage = "";
      var numbers = ticket.choosen_numbers.split(":");

      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedSpinNumbers.push(numbers[i].split("*")[0].trim());
          this.spinInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      this.spinUserIDToCancel = ticket.user_id;

      await this.spinRecietOverview(ticket.user_id);
      await this.spinCancelTicket();
    },
    async getSpinCopy(ticket, gameNumber) {
      this.copyMessage = "copy";
      var numbers = ticket.choosen_numbers.split(":");

      if (numbers[0] != "") {
        for (let i = 0; i < numbers.length; i++) {
          this.selectedSpinNumbers.push(numbers[i].split("*")[0].trim());
          this.spinInputMoney.push(numbers[i].split("*")[1].trim());
        }
      }

      this.spinUserNumber = ticket.user_id;
      this.spinGameDate = ticket.ticket_date;
      this.gameNumberToCopy = gameNumber.game_number;
      this.gameDateToCopy = gameNumber.game_date;

      await this.spinCopyOverview();
    },
    async cancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.userIDToCancel = ticket.user_id;

        await this.cancelTicket();
        await this.getMoneyOnHands();
        this.userIDToCancel = "";
      } else alert("You cannot delete");
    },
    async cancelTicket() {
      if (this.userIDToCancel != "") {
        this.cancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
               cancelTicket(
               user_id:"${this.userIDToCancel}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.cancelDialog = false;
            this.userIDToCancel = "";

            await this.getBalance();
            await this.getLastTwoGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.cancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async normalCancelTicket() {
      if (this.userIDToCancel != "") {
        this.cancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
                normalCancelTicket(
               user_id:"${this.userIDToCancel}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.normalCancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Cancelled!");
            this.cancelDialog = false;
            this.userIDToCancel = "";

            await this.getBalance();
            await this.getLastTwoGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.cancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async getBalance() {
      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getPartnerById(partner_id:${this.$store.state.cashier.partner_id}){
                     tempo_package
                     permanent_package
                     partner_balance
                     credit_chance
                 
                     day_block_date
                     day_block
                    

                   }

              }
              `,
          },
        });
        this.partnerBalance = partnerBalanceResult.data.data.getPartnerById[0];
        this.day_block = 0;
        var sec = 24 * 60 * 60 * 1000;

        this.day_block = Math.round(
          this.partnerBalance.day_block -
            (Date.now() -
              Number(
                this.partnerBalance.day_block_date != ""
                  ? this.partnerBalance.day_block_date
                  : 0
              )) /
              sec
        );
      } catch (err) {}

      try {
        var branchResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
                getBranchById(branch_id:${this.$store.state.cashier.branch_id}){
                    is_active
                     branch_id
                     branch_name
                     shop_number
                     ticket_layout
                     logo
                    
                    

                   }

              }
              `,
          },
        });
        this.brachData = branchResult.data.data.getBranchById[0];
      } catch (err) {}
    },

    async verify() {
      if (this.userIDToVerify != "") {
        if (
          Number(Date.now() - this.$store.state.sessionTime) >
          20 * 60 * 1000
        ) {
          this.$store.dispatch("loginCashier", "");
          this.$router.push({ name: "login" });
        } else {
          this.$store.dispatch("sessionTime", Date.now());
        }
        this.verifyLoading = true;
        await this.getOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
                
               verifyUser(
                branch_id: ${this.$store.state.cashier.branch_id},
                user_id:"${this.userIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled   

    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status

    }

    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }

                   }
              }
              `,
            },
          });
          this.verifyUserData = verifyResult.data.data.verifyUser;
          var totalWinOtherMoney = 0;
          var totalWinMoney = 0;
          this.payableList = [];

          if (this.verifyUserData.length > 0) {
            if (
              Number(this.verifyUserData[0].ticket_date) >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              if (this.verifyUserData[0].is_cancelled == 0) {
                if (this.verifyUserData[0].is_game_over == 1) {
                  if (this.verifyUserData[0].winner_award == 0) {
                    var tickets =
                      this.verifyUserData[0].choosen_numbers.split(":");
                    var others = this.verifyUserData[0].others.split(":");
                    if (
                      others[0] != "" &&
                      this.verifyUserData[0].getGame.length > 0
                    ) {
                      for (let r = 0; r < others.length; r++) {
                        if (
                          this.verifyUserData[0].getGame[0].high_low.toLowerCase() ==
                            others[r].split("*")[0].trim().toLowerCase() ||
                          this.verifyUserData[0].getGame[0].even_odd.toLowerCase() ==
                            others[r].split("*")[0].trim().toLowerCase()
                        ) {
                          for (
                            let x = 0;
                            x < this.$store.state.odds.length;
                            x++
                          ) {
                            if (
                              this.$store.state.odds[
                                x
                              ].choosen_length.toLowerCase() ==
                              others[r].split("*")[0].trim().toLowerCase()
                            ) {
                              this.payableList.push(
                                others[r] +
                                  " = " +
                                  parseFloat(
                                    this.$store.state.odds[x].odd_value
                                  ) *
                                    parseFloat(
                                      others[r].split("*")[1].trim()
                                    ).toString() +
                                  " Birr Won "
                              );
                              totalWinOtherMoney =
                                totalWinOtherMoney +
                                parseFloat(
                                  this.$store.state.odds[x].odd_value
                                ) *
                                  parseFloat(others[r].split("*")[1].trim());
                            }
                          }
                        } else this.payableList.push(others[r] + " = Loss ");
                      }
                    }

                    if (
                      tickets[0] != "" &&
                      this.verifyUserData[0].getGame.length > 0
                    ) {
                      for (let i = 0; i < tickets.length; i++) {
                        let countWin = 0;
                        var eachNumber = tickets[i].split("*")[0].split(",");
                        var eachAmount = parseFloat(tickets[i].split("*")[1]);

                        var matchCount = 0;
                        var drownNumbers =
                          this.verifyUserData[0].getGame[0].drawn_numbers.split(
                            ","
                          );
                        for (let k = 0; k < eachNumber.length; k++) {
                          for (let j = 0; j < drownNumbers.length; j++) {
                            if (drownNumbers[j] == eachNumber[k].trim()) {
                              matchCount++;
                            }
                          }
                        }

                        for (
                          let x = 0;
                          x < this.$store.state.odds.length;
                          x++
                        ) {
                          if (
                            parseInt(
                              this.$store.state.odds[x].choosen_length
                            ) == eachNumber.length
                          ) {
                            var hits =
                              this.$store.state.odds[x].hits.split(",");
                            var possibleWinMoney =
                              this.$store.state.odds[x].odd_value.split(",");

                            for (let y = 0; y < hits.length; y++) {
                              if (matchCount == parseInt(hits[y].trim())) {
                                countWin++;
                                this.payableList.push(
                                  tickets[i] +
                                    " = " +
                                    (
                                      (parseFloat(possibleWinMoney[y].trim()) *
                                        eachAmount) /
                                      10
                                    ).toString() +
                                    " Birr Won"
                                );
                                totalWinMoney =
                                  totalWinMoney +
                                  parseFloat(possibleWinMoney[y].trim()) *
                                    eachAmount;
                              }
                            }
                          }
                        }

                        if (countWin == 0)
                          this.payableList.push(tickets[i] + " = Loss ");
                      }
                    }

                    this.totalWinMoneyForOneUser =
                      totalWinMoney / 10 + totalWinOtherMoney;

                    this.verifyDialog = false;
                    this.winnersDialog = true;
                  } else alert("Already paid");
                } else alert("Game is not over");
              } else alert("Ticket cancelled");
            } else alert("Ticket expired");
          } else alert("Ticket is not exist");
        } catch (err) {
          alert(err);
        }
        this.verifyLoading = false;
        this.userIDToVerify = "";
      } else alert("Please scan Bar code or enter user id");
    },

    async getMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      var paidFromCashier = 0;
      var paidFromCashierTicket = 0;

      try {
        var paidForOtherCashierResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getPaidFromOtherCashier(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand =
          paidForOtherCashierResult.data.data.getPaidFromOtherCashier;

        for (let t = 0; t < moneyOnHand.length; t++) {
          paidFromCashier = paidFromCashier + moneyOnHand[t].winner_award;

          paidFromCashierTicket = paidFromCashierTicket + 1;
        }
      } catch (err) {}
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.totalMoneyOnHand = 0;
        this.totalMoneyPaid = 0;
        this.noTickets = 0;
        this.noPaidTickets = 0;
        this.noCancelledTickets = 0;
        this.noBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");
            var others = moneyOnHand[t].others.split(":");
            if (tickets[0] != "") {
              this.noBets = this.noBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.noBets = this.noBets + others.length;
              for (let k = 0; k < others.length; k++) {
                this.totalMoneyOnHand =
                  this.totalMoneyOnHand + parseFloat(others[k].split("*")[1]);
              }
            }
          }

          if (
            moneyOnHand[t].winner_award != 0 &&
            moneyOnHand[t].cashier_id == moneyOnHand[t].payer_cashier_id
          ) {
            this.totalMoneyPaid =
              this.totalMoneyPaid + moneyOnHand[t].winner_award;

            this.noPaidTickets = this.noPaidTickets + 1;
          }

          if (moneyOnHand[t].is_cancelled == 1) {
            this.noCancelledTickets = this.noCancelledTickets + 1;
          } else this.noTickets = this.noTickets + 1;
        }

        this.totalMoneyPaid = this.totalMoneyPaid + paidFromCashier;

        this.noPaidTickets = this.noPaidTickets + paidFromCashierTicket;
      } catch (err) {}

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
               getMoneyFromYesterday(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterday;
        this.paidKenoMoneyFromYesterday = 0;
        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          this.paidKenoMoneyFromYesterday =
            this.paidKenoMoneyFromYesterday +
            paidMoneyFromYesterday[k].winner_award;
        }
      } catch (err) {}
      var jack = 0;

      try {
        var paidMoneyJackpotdayResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `{
              getJackpotPaidMoney(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                jackpot_history_id     
                amount
                   }
              }
              `,
          },
        });

        var paidMoneyFromJackpot =
          paidMoneyJackpotdayResult.data.data.getJackpotPaidMoney;

        for (let k = 0; k < paidMoneyFromJackpot.length; k++) {
          jack = jack + paidMoneyFromJackpot[k].amount;
        }
      } catch (err) {}

      try {
        var paidMoneyJackpotResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
              getJackpotPaidMoney(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                jackpot_history_id     
                amount
                   }
              }
              `,
          },
        });

        var paidMoneyFromJackpot =
          paidMoneyJackpotResult.data.data.getJackpotPaidMoney;

        for (let k = 0; k < paidMoneyFromJackpot.length; k++) {
          jack = jack + paidMoneyFromJackpot[k].amount;
        }
      } catch (err) {}

      this.paidJackpotMoney = jack;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async logout() {
      this.logoutLoading = true;
      try {
        var loggedinResult = await axios({
          method: "POST",
          url: this.$store.state.cashierURL,

          data: {
            query: `mutation{
               loggedInCashier(
                cashier_id:${this.$store.state.cashier.cashier_id},
                is_loggedin:0
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        if (
          loggedinResult.data.data.loggedInCashier[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.logoutDialog) {
            try {
              var cashierReportResult = await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
               addCashierReport(
                cashier_name:"${
                  this.$store.state.cashier.cashier_full_name
                }",              
                loggin_time: "${this.$store.state.cashier.loggin_time}",
          
                logout_time: "${Date.now()}",
                locationCoordinat: "${this.currentLocation}",
                ip_address: "${this.ipAddress}",
                total_tickets: ${this.noTickets + this.spinNoTickets},
                paid_tickets: ${this.noPaidTickets + this.spinNoPaidTickets},
                cancelled_tickets: ${
                  this.noCancelledTickets + this.spinNoCancelledTickets
                },
                number_bets: ${this.noBets + this.spinNoBets},
          
                total_money: ${
                  this.totalMoneyOnHand + this.spinTotalMoneyOnHand
                },
                paid_money: ${
                  this.totalMoneyPaid +
                  this.spinTotalMoneyPaid +
                  this.paidKenoMoneyFromYesterday +
                  this.paidSpinMoneyFromYesterday
                },
                onhand_money: ${
                  this.totalMoneyOnHand -
                  this.totalMoneyPaid -
                  this.paidKenoMoneyFromYesterday +
                  (this.spinTotalMoneyOnHand -
                    this.spinTotalMoneyPaid -
                    this.paidSpinMoneyFromYesterday)
                },
                   )
                {
                      statusMessage

                   }
              }
              `,
                },
              });
              if (
                cashierReportResult.data.data.addCashierReport[0].statusMessage.toString() ==
                "true"
              ) {
                var content = this.$refs.printReport;

                var newWindow = window.open("");
                this.logoutDialog = false;

                newWindow.document.write(content.outerHTML);

                newWindow.print();
                newWindow.close();

                this.$router.push({ name: "login" });
                this.$store.dispatch("loginCashier", "");

                this.$router.go();
              } else alert("Some thing went wrong, contact the admin");
            } catch (err) {
              alert(err);
            }
          }

          this.$router.push({ name: "login" });
          this.$store.dispatch("loginCashier", "");

          this.$router.go();
        } else alert("Some thing went wrong, contact the admin");
      } catch (err) {
        alert(err);
      }
      this.logoutLoading = false;
    },

    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords.latitude + "," + position.coords.longitude);
          },
          (error) => {
            console.log(error.message);
          }
        );
      });
      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async logoutClicked() {
      this.currentLocation = await this.getLocation();
      this.ipAddress = await this.getIPAddress();
      this.logoutDialog = true;
    },

    //spin this.spinCurrentMousePosition= this.selectedSpinNumbers.length-1

    spinAssignSelectedAmount(amount) {
      this.spinSelectedCustomAmount = amount;
      if (this.selectedSpinNumbers.length > 0)
        this.spinInputMoney[this.spinCurrentMousePosition] =
          this.spinSelectedCustomAmount;
    },

    spinApplyToAll() {
      this.spinInputMoney = [];

      for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
        this.spinInputMoney.push(this.spinSelectedCustomAmount);
      }
    },

    spinclearAll() {
      this.spinInputMoney = [];
      this.spinSelectedCustomAmount = 0;
    },
    selectSpinNumbers(number) {
      this.selectedSpinNumbers.push(number.toString());
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    spinRemoveSelectedNumbers(index) {
      this.selectedSpinNumbers.splice(index, 1);
      this.spinInputMoney.splice(index, 1);
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    chooseNeighbor(number) {
      var splitedNumbers = "";
      for (let i = 0; i < this.spinNumbers.length; i++) {
        if (number == this.spinNumbers[i].number) {
          splitedNumbers =
            this.spinNumbers[i].neighbors.split(",")[0].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[1].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[2].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[3].split(" ")[0].trim() +
            "," +
            this.spinNumbers[i].neighbors.split(",")[4].split(" ")[0].trim();
        }
      }
      this.selectedSpinNumbers.push(
        number.toString() + " Neighbors (" + splitedNumbers + ")"
      );
      this.neighboursPopUp = false;
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    chooseTwoNumbers(number) {
      if (this.twoNumbers.length < 2) {
        if (this.twoNumbers.indexOf(number) == -1) {
          this.twoNumbers.push(number);
        }
      }
    },

    generateRandomBet() {
      var index = Math.floor(Math.random() * this.randomGame.length);

      if (this.randomGame[index] == "Numbers") {
        var drownNumbers = Math.floor(Math.random() * 36) + 1;
        this.selectedSpinNumbers.push(drownNumbers.toString());
      } else if (this.randomGame[index] == "Neighbours") {
        var drownNumbers = Math.floor(Math.random() * (36 + 1));
        var splitedNumbers = "";
        for (let i = 0; i < this.spinNumbers.length; i++) {
          if (drownNumbers == this.spinNumbers[i].number) {
            splitedNumbers =
              this.spinNumbers[i].neighbors.split(",")[0].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[1].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[2].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[3].split(" ")[0].trim() +
              "," +
              this.spinNumbers[i].neighbors.split(",")[4].split(" ")[0].trim();
          }
        }
        this.selectedSpinNumbers.push(
          drownNumbers.toString() + " Neighbors (" + splitedNumbers + ")"
        );
      } else {
        this.selectedSpinNumbers.push(this.randomGame[index]);
      }
      this.spinCurrentMousePosition = this.selectedSpinNumbers.length - 1;
    },

    async spinRecietOverview(id) {
      if (Number(Date.now() - this.$store.state.sessionTime) > 20 * 60 * 1000) {
        this.$store.dispatch("loginCashier", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      this.copyMessage = "";
      let totalMoney = 0;
      this.spinTotalPossibleWin = 0;
      var emptyCounter = 0;
      for (let v = 0; v < this.spinInputMoney.length; v++) {
        if (this.spinInputMoney[v] < 10 || this.spinInputMoney[v] > 2000)
          emptyCounter++;
      }

      for (let m = 0; m < this.spinInputMoney.length; m++) {
        if (this.spinInputMoney[m] == "" || this.spinInputMoney[m] == undefined)
          emptyCounter++;
      }
      if (
        this.selectedSpinNumbers.length == this.spinInputMoney.length &&
        emptyCounter == 0
      ) {
        this.spinUserNumber = Date.now();
        this.spinGameDate = Date.now();
        var choosenNumbers = "";

        if (
          this.formatDate(Date.now()) != this.$store.state.spinTicketNumberDate
        ) {
          this.$store.dispatch(
            "spinTicketNumberDate",
            this.formatDate(Date.now())
          );

          this.$store.dispatch("spinTicketNumber", 0);
        }
        this.$store.dispatch(
          "spinTicketNumber",
          this.$store.state.spinTicketNumber + 1
        );

        for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
          totalMoney = totalMoney + parseFloat(this.spinInputMoney[i]);
          // for (let j = 0; j < this.$store.state.odds.length; j++) {
          if (this.selectedSpinNumbers[i].includes("Neighbors")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].neighbors;
          } else if (this.selectedSpinNumbers[i].includes("&")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low_color;
          } else if (this.selectedSpinNumbers[i].includes("Donzen")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].dozen;
          } else if (this.selectedSpinNumbers[i].includes("Sector")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].sector;
          } else if (this.selectedSpinNumbers[i].includes("Final")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].final;
          } else if (
            this.selectedSpinNumbers[i] == "Red" ||
            this.selectedSpinNumbers[i] == "Black" ||
            this.selectedSpinNumbers[i] == "High" ||
            this.selectedSpinNumbers[i] == "Low" ||
            this.selectedSpinNumbers[i] == "Even" ||
            this.selectedSpinNumbers[i] == "Odd"
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low;
          } else if (this.selectedSpinNumbers[i].includes("Green")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          } else if (this.selectedSpinNumbers[i].includes("Twins")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].twins;
          } else if (this.selectedSpinNumbers[i].includes("|")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].mirror;
          } else if (
            this.selectedSpinNumbers[i].includes(",") &&
            this.selectedSpinNumbers[i].split(",").length == 2
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              (parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number) /
                2;
          } else if (!isNaN(this.selectedSpinNumbers[i])) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          }
          var finalNumbers = this.selectedSpinNumbers[i];
          if (finalNumbers.includes("Neighbors")) {
            finalNumbers = finalNumbers.split("(")[0].trim();
          }
          if (choosenNumbers == "") {
            choosenNumbers = finalNumbers + "*" + this.spinInputMoney[i];
          } else
            choosenNumbers =
              choosenNumbers +
              ":" +
              finalNumbers +
              "*" +
              this.spinInputMoney[i];
        }

        if (this.partnerBalance.tempo_package >= totalMoney) {
          this.spinTotalInputMoney = totalMoney;
          this.spinPrintDialog = true;

          try {
            var ticketResult = await axios({
              method: "POST",
              url: this.$store.state.spinCashierURL,

              data: {
                query: `mutation{
             saveTicket(
              game_id:${this.spinCurrentGame[0].game_id},
             user_id:"${this.spinUserNumber}",
             ticket_number:${this.$store.state.spinTicketNumber},
             cashier_id:${this.$store.state.cashier.cashier_id},
             partner_id:${this.$store.state.cashier.partner_id},
             branch_id:${this.$store.state.cashier.branch_id},
             choosen_numbers: "${choosenNumbers}",
             others: "${""}",
             replaced_user_id: "${id}",
             ticket_date: "${this.spinGameDate}",
             tempo_package:${this.partnerBalance.tempo_package - totalMoney}

             )
              {
                    statusMessage

                 }
            }
            `,
              },
            });

            if (
              ticketResult.data.data.saveTicket[0].statusMessage.toString() ==
              "true"
            ) {
              await axios({
                method: "POST",
                url: this.$store.state.cashierURL,

                data: {
                  query: `mutation{
             updatePackageAfterTicket(  
              partner_id:${this.$store.state.cashier.partner_id},           
             tempo_package:${this.partnerBalance.tempo_package - totalMoney}

             )
              {
                    statusMessage

                 }
            }
            `,
                },
              });

              var content = this.$refs.spinPrintable;

              var newWindow = window.open("");

              newWindow.document.write(content.outerHTML);
              setTimeout(function () {
                newWindow.print();
                newWindow.close();
              }, 140);
              this.spinPrintDialog = false;

              await this.getBalance();
              await this.getLastTwoSpinGames();
              this.selectedSpinNumbers = [];

              this.spinInputMoney = [];
            } else alert("Ticket not printed, try again");
          } catch (err) {
            alert(err);
          }
        } else alert("Insufficient funds, please deposit to continue");
      } else
        alert(
          "Please fill all fields, amounts And make sure the amount is between 10 to 2000 birr"
        );
    },

    async spinCopyOverview() {
      this.copyMessage = "copy";
      let totalMoney = 0;
      this.spinTotalPossibleWin = 0;
      var emptyCounter = 0;
      for (let v = 0; v < this.spinInputMoney.length; v++) {
        if (this.spinInputMoney[v] < 10 || this.spinInputMoney[v] > 2000)
          emptyCounter++;
      }

      for (let m = 0; m < this.spinInputMoney.length; m++) {
        if (this.spinInputMoney[m] == "" || this.spinInputMoney[m] == undefined)
          emptyCounter++;
      }
      if (
        this.selectedSpinNumbers.length == this.spinInputMoney.length &&
        emptyCounter == 0
      ) {
        var choosenNumbers = "";

        for (let i = 0; i < this.selectedSpinNumbers.length; i++) {
          totalMoney = totalMoney + parseFloat(this.spinInputMoney[i]);
          // for (let j = 0; j < this.$store.state.odds.length; j++) {
          if (this.selectedSpinNumbers[i].includes("Neighbors")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].neighbors;
          } else if (this.selectedSpinNumbers[i].includes("&")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low_color;
          } else if (this.selectedSpinNumbers[i].includes("Donzen")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].dozen;
          } else if (this.selectedSpinNumbers[i].includes("Sector")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].sector;
          } else if (this.selectedSpinNumbers[i].includes("Final")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].final;
          } else if (
            this.selectedSpinNumbers[i] == "Red" ||
            this.selectedSpinNumbers[i] == "Black" ||
            this.selectedSpinNumbers[i] == "High" ||
            this.selectedSpinNumbers[i] == "Low" ||
            this.selectedSpinNumbers[i] == "Even" ||
            this.selectedSpinNumbers[i] == "Odd"
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].high_low;
          } else if (this.selectedSpinNumbers[i].includes("Green")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          } else if (this.selectedSpinNumbers[i].includes("Twins")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].twins;
          } else if (this.selectedSpinNumbers[i].includes("|")) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].mirror;
          } else if (
            this.selectedSpinNumbers[i].includes(",") &&
            this.selectedSpinNumbers[i].split(",").length == 2
          ) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              (parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number) /
                2;
          } else if (!isNaN(this.selectedSpinNumbers[i])) {
            this.spinTotalPossibleWin =
              this.spinTotalPossibleWin +
              parseFloat(this.spinInputMoney[i]) *
                this.$store.state.spinOdds[0].number;
          }
          var finalNumbers = this.selectedSpinNumbers[i];
          if (finalNumbers.includes("Neighbors")) {
            finalNumbers = finalNumbers.split("(")[0].trim();
          }
          if (choosenNumbers == "") {
            choosenNumbers = finalNumbers + "*" + this.spinInputMoney[i];
          } else
            choosenNumbers =
              choosenNumbers +
              ":" +
              finalNumbers +
              "*" +
              this.spinInputMoney[i];
        }

        this.spinTotalInputMoney = totalMoney;
        this.spinPrintDialog = true;

        setTimeout(
          function () {
            this.printSpinCopy();
          }.bind(this),
          140
        );
      } else
        alert(
          "Please fill all fields, amounts And make sure the amount is between 10 to 2000 birr"
        );
    },

    printSpinCopy() {
      try {
        var content = this.$refs.spinPrintable;

        var newWindow = window.open("");

        newWindow.document.write(content.outerHTML);
        setTimeout(function () {
          newWindow.print();
          newWindow.close();
        }, 140);
        this.spinPrintDialog = false;

        this.selectedSpinNumbers = [];

        this.spinInputMoney = [];
        this.copyMessage = "";
      } catch (err) {
        alert(err);
      }
    },
    async getCurrentSpinGame() {
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getPendingOrPlayingGame(cashier_id:${this.$store.state.cashier.cashier_id},partner_id:${this.$store.state.cashier.partner_id}){
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
		                  even_odd
                      game_status

                   }
              }
              `,
          },
        });
        this.spinCurrentGame =
          currentGameResult.data.data.getPendingOrPlayingGame;

        if (this.spinCurrentGame.length > 0) {
          this.spinTimeInSeconds = Math.floor(
            270 -
              (Date.now() - Number(this.spinCurrentGame[0].game_date)) / 1000
          );

          this.spinTimePercent =
            0.3704 *
            Math.floor(
              (Date.now() - Number(this.spinCurrentGame[0].game_date)) / 1000
            );
        } else {
          this.spinTimePercent = 0;
          this.spinMinutes = "00";
          this.spinSeconds = "00";
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },

    async getLastTwoSpinGames() {
      try {
        var lastGameResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                  getLastTwoGames(cashier_id:${this.$store.state.cashier.cashier_id}){
                       game_id

                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                       game_status
                       getTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                       }

                       getLastTickets{
                         cashier_id
                         ticket_id
                         game_id
                         user_id
                         choosen_numbers
                         others
                         ticket_date
                         winner_award
                         is_cancelled
                         getGame{
                          game_id
                          game_number
                           }
                       }
                     }


              }
              `,
          },
        });

        this.spinLastTwoGames = lastGameResult.data.data.getLastTwoGames;
        if (this.spinLastTwoGames.length > 0) {
          var lastGame = this.spinLastTwoGames[0].getTickets;
          this.spinIsDuplicated = false;
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j - 1].others == ticket.others &&
                    lastGame[j - 1].cashier_id == ticket.cashier_id) ||
                  (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                    lastGame[j + 1].others == ticket.others &&
                    lastGame[j + 1].cashier_id == ticket.cashier_id)
                ) {
                  this.spinIsDuplicated = true;
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id
                ) {
                  this.spinIsDuplicated = true;
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id
                ) {
                  this.spinIsDuplicated = true;
                }
              }
            }
          }
        }

        // eslint-disable-next-line no-empty
      } catch (err) {}
    },

    async spinGetOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                getOdds{
                  	odds_id
		                number
		                color
		                mirror
		                twins
		                final
		                dozen

		                sector
		                high_low_color
		                even_odd
		                high_low
		                neighbors

                }

              }
              `,
          },
        });

        this.$store.dispatch("spinOdds", oddsResult.data.data.getOdds);
        // eslint-disable-next-line no-empty
      } catch (err) {}

      try {
        var spinnumbersResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
                getSpinNumbers{
                   spin_number_id
                   number                 
                   neighbors
                    }
              }
              `,
          },
        });

        this.spinNumbers = spinnumbersResult.data.data.getSpinNumbers;
      } catch (err) {}
    },

    async spinReportTicket() {
      if (this.spinUserIdForReport != "" && this.spinReportReason != "") {
        this.spinReportLoading = true;
        try {
          var reportResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
               reportTicket(user_id:"${this.spinUserIdForReport}",
              reason: "${this.spinReportReason}"){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            reportResult.data.data.reportTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Reported successfully!");
            this.spinReportTicketDialog = false;
          } else alert("Some thing went wrong");
        } catch (err) {
          alert(err);
        }
        this.spinReportLoading = false;
      } else alert("Please fill the fields");
    },

    async spinPayWinnerAward() {
      this.spinPayLoading = true;
      try {
        var payResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `mutation{
               payWinnerAward(ticket_id:${this.spinVerifyUserData[0].ticket_id},
               payer_cashier_id: ${this.$store.state.cashier.cashier_id},
               user_id:"${this.spinVerifyUserData[0].user_id}",
                winner_award: ${this.spinTotalWinMoneyForOneUser}){
                      statusMessage

                   }
              }
              `,
          },
        });
        if (
          payResult.data.data.payWinnerAward[0].statusMessage.toString() ==
          "true"
        ) {
          if (this.spinVerifyUserData[0].getJackpotHistory.length > 0) {
            await axios({
              method: "POST",
              url: this.$store.state.spinCashierURL,

              data: {
                query: `mutation{
              editJackpotHistory(jackpot_history_id:${this.spinVerifyUserData[0].getJackpotHistory[0].jackpot_history_id},
              cashier_id: ${this.$store.state.cashier.cashier_id},
              partner_id: ${this.$store.state.cashier.partner_id},
           ){
                      statusMessage

                   }
              }
              `,
              },
            });
          }
          await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
             updatePackageAfterTicket(
              partner_id:${this.$store.state.cashier.partner_id},              
             tempo_package:${
               this.partnerBalance.tempo_package +
               this.spinTotalWinMoneyForOneUser
             }

             )
              {
                    statusMessage

                 }
            }
            `,
            },
          });

          if (
            Number(this.spinVerifyUserData[0].ticket_date) <
            new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinCashierURL,

              data: {
                query: `mutation{
                   addPaidFromYesterday(cashier_id:${this.$store.state.cashier.cashier_id},
                   user_id:"${this.spinVerifyUserData[0].user_id}",
                    winner_award: ${this.spinTotalWinMoneyForOneUser}){
                          statusMessage
    
                       }
                  }
                  `,
              },
            });
          }

          this.spinWinnersDialog = false;
          this.SpinVerifyDialog = true;
          await this.getBalance();
          this.spinUserIDToVerify = "";
        }
      } catch (err) {
        alert(err);
      }

      this.spinPayLoading = false;
    },
    async spinCancelDuplicatedTicket(ticket, lastGame, j) {
      let isDuplicated = false;
      if (j != 0) {
        if (lastGame.length != j + 1) {
          if (
            (lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j - 1].others == ticket.others &&
              lastGame[j - 1].cashier_id == ticket.cashier_id) ||
            (lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
              lastGame[j + 1].others == ticket.others &&
              lastGame[j + 1].cashier_id == ticket.cashier_id)
          ) {
            isDuplicated = true;
          }
        } else {
          if (
            lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j - 1].others == ticket.others &&
            lastGame[j - 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      } else {
        if (lastGame.length != j + 1) {
          if (
            lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
            lastGame[j + 1].others == ticket.others &&
            lastGame[j + 1].cashier_id == ticket.cashier_id
          ) {
            isDuplicated = true;
          }
        }
      }

      if (isDuplicated) {
        this.spinUserIDToCancel = ticket.user_id;

        await this.spinCancelTicket();
        await this.getSpinMoneyOnHands();
        this.spinUserIDToCancel = "";
      } else alert("You cannot delete");
    },
    async spinCancelTicket() {
      if (this.spinUserIDToCancel != "") {
        this.spinCancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
               cancelTicket(
               user_id:"${this.spinUserIDToCancel}",
               tempo_package:${this.partnerBalance.tempo_package},
               cashierURL: "${this.$store.state.cashierURL}",  
               ){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.cancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            this.spinCancelDialog = false;
            this.spinUserIDToCancel = "";

            await this.getBalance();
            await this.getLastTwoSpinGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.spinCancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async normalSpinCancelTicket() {
      if (this.spinUserIDToCancel != "") {
        this.spinCancelLoading = true;
        try {
          var cancelResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `mutation{
                normalSpinCancelTicket(
               user_id:"${this.spinUserIDToCancel}",
               tempo_package:${this.partnerBalance.tempo_package},
               cashierURL: "${this.$store.state.cashierURL}",  
               ){
                      statusMessage

                   }
              }
              `,
            },
          });
          if (
            cancelResult.data.data.normalSpinCancelTicket[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Cancelled!");
            this.spinCancelDialog = false;
            this.spinUserIDToCancel = "";

            await this.getBalance();
            await this.getLastTwoSpinGames();
          } else alert("You can not cancel the ticket, or already cancelled");
        } catch (err) {
          alert(err);
        }

        this.spinCancelLoading = false;
      } else alert("Please scan Bar code or enter user id");
    },

    async spinVerify() {
      if (this.spinUserIDToVerify != "") {
        if (
          Number(Date.now() - this.$store.state.sessionTime) >
          20 * 60 * 1000
        ) {
          this.$store.dispatch("loginCashier", "");
          this.$router.push({ name: "login" });
        } else {
          this.$store.dispatch("sessionTime", Date.now());
        }

        this.spinVerifyLoading = true;
        await this.spinGetOdds();
        try {
          var verifyResult = await axios({
            method: "POST",
            url: this.$store.state.spinCashierURL,

            data: {
              query: `{
               verifyUser(
                branch_id: ${this.$store.state.cashier.branch_id},
                user_id:"${this.spinUserIDToVerify}")
                {
                        ticket_id
    game_id
    user_id
    choosen_numbers
    others
    ticket_date
    winner_award
    is_game_over
    is_cancelled  
    getJackpotHistory{
      jackpot_history_id
      cashier_id
      ticket_id
      selected_no
      amount
      jack_date
      jack_type
      is_paid
    }
    getGame{
      game_id
      game_number
      drawn_numbers
      game_date
      high_low
      even_odd
      game_status
      getSpinNumber{
        spin_number_id
        number
        color
        mirror
        twins
        final
        dozen
        sector
        combination
        even_odd
        high_low
        neighbors
      }

    }

                   }
              }
              `,
            },
          });
          this.spinVerifyUserData = verifyResult.data.data.verifyUser;

          var totalPossibleWinMoney = 0;
          this.payableList = [];

          if (this.spinVerifyUserData.length > 0) {
            if (
              Number(this.spinVerifyUserData[0].ticket_date) >
              Date.now() - 48 * 60 * 60 * 1000
            ) {
              if (this.spinVerifyUserData[0].is_cancelled == 0) {
                if (this.spinVerifyUserData[0].is_game_over == 1) {
                  if (this.spinVerifyUserData[0].winner_award == 0) {
                    var tickets =
                      this.spinVerifyUserData[0].choosen_numbers.split(":");
                    var spinNum =
                      this.spinVerifyUserData[0].getGame[0].getSpinNumber;
                    var rowOut2 = this.$store.state.spinOdds;
                    if (
                      tickets[0] != "" &&
                      this.spinVerifyUserData[0].getGame.length > 0
                    ) {
                      for (let i = 0; i < tickets.length; i++) {
                        let countWin = 0;
                        var eachNumber = tickets[i].split("*")[0].trim();

                        var eachAmount = parseFloat(tickets[i].split("*")[1]);

                        if (spinNum[0].number.toString() == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].number * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].number * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (eachNumber.includes(",")) {
                          if (
                            eachNumber.split(",")[0].trim() ==
                              spinNum[0].number.toString() ||
                            eachNumber.split(",")[1].trim() ==
                              spinNum[0].number.toString()
                          ) {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              (rowOut2[0].number / 2) * eachAmount;

                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (
                                  (rowOut2[0].number / 2) *
                                  eachAmount
                                ).toString() +
                                " Birr Won"
                            );
                          }
                        } else if (spinNum[0].color == eachNumber) {
                          if (eachNumber == "0 Green") {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              rowOut2[0].number * eachAmount;

                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (rowOut2[0].number * eachAmount).toString() +
                                " Birr Won"
                            );
                          } else {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              rowOut2[0].high_low * eachAmount;
                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (rowOut2[0].high_low * eachAmount).toString() +
                                " Birr Won"
                            );
                          }
                        } else if (spinNum[0].sector == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].sector * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].sector * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].even_odd == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].even_odd * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].even_odd * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].high_low == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].high_low * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].high_low * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (
                          spinNum[0].neighbors.split(",")[0].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[1].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[2].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[3].trim() ==
                            eachNumber ||
                          spinNum[0].neighbors.split(",")[4].trim() ==
                            eachNumber
                        ) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].neighbors * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].neighbors * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].twins == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].twins * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].twins * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].dozen == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].dozen * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].dozen * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (eachNumber.includes("Final")) {
                          if (spinNum[0].final.includes(eachNumber)) {
                            totalPossibleWinMoney =
                              totalPossibleWinMoney +
                              rowOut2[0].final * eachAmount;

                            countWin++;

                            this.payableList.push(
                              tickets[i] +
                                " = " +
                                (rowOut2[0].final * eachAmount).toString() +
                                " Birr Won"
                            );
                          }
                        } else if (spinNum[0].mirror == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].mirror * eachAmount;
                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (rowOut2[0].mirror * eachAmount).toString() +
                              " Birr Won"
                          );
                        } else if (spinNum[0].combination == eachNumber) {
                          totalPossibleWinMoney =
                            totalPossibleWinMoney +
                            rowOut2[0].high_low_color * eachAmount;

                          countWin++;

                          this.payableList.push(
                            tickets[i] +
                              " = " +
                              (
                                rowOut2[0].high_low_color * eachAmount
                              ).toString() +
                              " Birr Won"
                          );
                        }

                        if (countWin == 0)
                          this.payableList.push(tickets[i] + " = Loss ");
                      }
                    }

                    this.spinTotalWinMoneyForOneUser = totalPossibleWinMoney;

                    this.SpinVerifyDialog = false;
                    this.spinWinnersDialog = true;
                  } else alert("Already paid");
                } else alert("Game is not over");
              } else alert("Ticket cancelled");
            } else alert("Ticket expired");
          } else alert("Ticket is not exist");
        } catch (err) {
          alert(err);
        }
        this.spinVerifyLoading = false;
        this.spinUserIDToVerify = "";
      } else alert("Please scan Bar code or enter user id");
    },

    async getSpinMoneyOnHands() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      var paidFromCashier = 0;
      var paidFromCashierTicket = 0;
      try {
        var paidForOtherCashierResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getPaidFromOtherCashier(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand =
          paidForOtherCashierResult.data.data.getPaidFromOtherCashier;

        for (let t = 0; t < moneyOnHand.length; t++) {
          paidFromCashier = paidFromCashier + moneyOnHand[t].winner_award;
          paidFromCashierTicket = paidFromCashierTicket + 1;
        }
      } catch (err) {}
      try {
        var moneyOnHandsResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getMoneyOnHands(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    choosen_numbers
                    cashier_id
                    others
                    winner_award
                    is_cancelled
                    payer_cashier_id

                   }
              }
              `,
          },
        });

        var moneyOnHand = moneyOnHandsResult.data.data.getMoneyOnHands;
        this.spinTotalMoneyOnHand = 0;
        this.spinTotalMoneyPaid = 0;
        this.spinNoTickets = 0;
        this.spinNoPaidTickets = 0;
        this.spinNoCancelledTickets = 0;
        this.spinNoBets = 0;

        //for (let i = 0; i < moneyOnHand.length; i++) {
        for (let t = 0; t < moneyOnHand.length; t++) {
          if (moneyOnHand[t].is_cancelled == 0) {
            var tickets = moneyOnHand[t].choosen_numbers.split(":");
            var others = moneyOnHand[t].others.split(":");
            if (tickets[0] != "") {
              this.spinNoBets = this.spinNoBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.spinTotalMoneyOnHand =
                  this.spinTotalMoneyOnHand +
                  parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.spinNoBets = this.spinNoBets + others.length;
              for (let k = 0; k < others.length; k++) {
                this.spinTotalMoneyOnHand =
                  this.spinTotalMoneyOnHand +
                  parseFloat(others[k].split("*")[1]);
              }
            }
          }

          if (
            moneyOnHand[t].winner_award != 0 &&
            moneyOnHand[t].payer_cashier_id == moneyOnHand[t].cashier_id
          ) {
            this.spinTotalMoneyPaid =
              this.spinTotalMoneyPaid + moneyOnHand[t].winner_award;
            this.spinNoPaidTickets = this.spinNoPaidTickets + 1;
          }

          if (moneyOnHand[t].is_cancelled == 1) {
            this.spinNoCancelledTickets = this.spinNoCancelledTickets + 1;
          } else this.spinNoTickets = this.spinNoTickets + 1;
        }
        this.spinTotalMoneyPaid = this.spinTotalMoneyPaid + paidFromCashier;

        this.spinNoPaidTickets = this.spinNoPaidTickets + paidFromCashierTicket;

        // eslint-disable-next-line no-empty
      } catch (err) {}

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.spinCashierURL,

          data: {
            query: `{
               getMoneyFromYesterday(cashier_id: ${this.$store.state.cashier.cashier_id}, date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterday;
        this.paidSpinMoneyFromYesterday = 0;
        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          this.paidSpinMoneyFromYesterday =
            this.paidSpinMoneyFromYesterday +
            paidMoneyFromYesterday[k].winner_award;
        }
      } catch (err) {}
    },
  },

  async created() {
    if (this.$store.state.cashier != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 20 * 60 * 1000) {
        this.$store.dispatch("loginCashier", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }

      this.getHelp();
      this.getCommonFeatures();
      this.getBalance();

      this.getOdds();
      this.spinGetOdds();

      setInterval(async () => {
        await this.getCurrentGame();

        setTimeout(async () => {
          axios.get(
            this.$store.state.cashierURL.split("graphql")[0] + "getApp"
          );
        }, 20000);

        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          this.getMoneyOnHands();
          this.getBalance();
          this.getBlockedCashier();
          this.getLastTwoGames();
          // if (this.whichToShow == "keno") {

          if (this.currentGame.length == 0) {
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.currentGame[0].game_status == "pending") {
              if (this.timeInSeconds > 0) {
                this.isPlaying = false;
                this.timeInSeconds--;
                let hours = Math.floor(this.timeInSeconds / 3600);
                this.minutes = Math.floor(
                  (this.timeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.seconds =
                  this.timeInSeconds - hours * 3600 - this.minutes * 60;

                if (this.minutes < 10) {
                  this.minutes = "0" + this.minutes;
                }
                if (this.seconds < 10) {
                  this.seconds = "0" + this.seconds;
                }

                this.timePercent = this.timePercent + 0.4238;
              } else {
                this.isPlaying = true;
              }
            } else {
              this.isPlaying = true;
            }
          }
        } else {
          this.getBalance();
        }
      }, 1000);
      setInterval(async () => {
        await this.getCurrentSpinGame();
        if (Number(this.minutes) == 1 && Number(this.seconds) <= 52)
          await axios.get(
            this.$store.state.spinCashierURL.split("graphql")[0] + "getApp"
          );
        if (
          this.partnerBalance.tempo_package != 0 &&
          this.partnerBalance.tempo_package <
            2 * this.partnerBalance.permanent_package
        ) {
          this.getSpinMoneyOnHands();
          // if (this.whichToShow == "spin") {

          this.getLastTwoSpinGames();

          if (this.spinCurrentGame.length == 0) {
            alert(
              "There is no game,Something went wrong, please refresh the page"
            );
          } else {
            if (this.spinCurrentGame[0].game_status == "pending") {
              if (this.spinTimeInSeconds > 0) {
                this.spinIsPlaying = false;
                this.spinTimeInSeconds--;
                let hours = Math.floor(this.spinTimeInSeconds / 3600);
                this.spinMinutes = Math.floor(
                  (this.spinTimeInSeconds - hours * 3600) / 60
                ); // get minutes
                this.spinSeconds =
                  this.spinTimeInSeconds - hours * 3600 - this.spinMinutes * 60;

                if (this.spinMinutes < 10) {
                  this.spinMinutes = "0" + this.spinMinutes;
                }
                if (this.spinSeconds < 10) {
                  this.spinSeconds = "0" + this.spinSeconds;
                }

                this.spinTimePercent = this.spinTimePercent + 0.3704;
              } else {
                this.spinIsPlaying = true;
              }
            } else {
              this.spinIsPlaying = true;
            }
          }
        }
      }, 1000);
    } else this.$router.push({ name: "login" });

    this.isLoad = false;
  },
};
</script>
<style scoped>
#navBar {
  background-color: rgba(27, 42, 61, 255);
}
#numbers,
#selectedNumbers {
  border-radius: 4px;
  background-color: rgba(41, 57, 73, 255);
  color: white;
}
#numbers:hover {
  background-color: rgba(41, 20, 30, 255);
}

#ticketListVertically {
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: scaleX(-1);
}

#clearBtn:hover,
#applyToAll:hover {
  background-color: rgba(41, 20, 30, 255);
  color: white;
}
#clearBtn {
  border-radius: 4px;
  background-color: rgb(233, 68, 95);
  color: white;
}
#printBtn {
  background-color: rgba(41, 57, 73, 255);
}

#applyToAll {
  border-radius: 4px;
  background-color: white;
  color: grey;
}
.circle {
  width: 30px;
  height: 30px;

  border-radius: 50%;

  color: #fff;
  text-align: center;
  border: 1px solid white;
  float: center;
  margin-left: 20%;
  padding-top: 2px;
  font-size: 14px;
}

#selectedNum {
  border-radius: 4px;
}
</style>
