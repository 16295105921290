<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="white" style="height: 100%">
    <div class="pt-10" id="loginContainer">
      <v-form @submit.prevent="" id="login">
        <v-card id="loginCard" elevation="10" class="pt-16 pb-5 mt-6">
          <v-layout row wrap class="text-center pa-10 pl-3 pt-5 mt-16" id="">
            <v-flex xs5 class="mt-10 text-left">
              <v-img width="70%" src="../assets/Red and White.png"> </v-img>
              <br />
              <br />
              <br />
              <br />

              <!-- <h1 class="headline white--text pl-3" style="font-weight: bold">
                Apice virtual
              </h1> -->
            </v-flex>
            <v-flex xs5 class="">
              <v-layout row wrap>
                <v-flex xs12>
                  <p class="headline" style="font-weight: bold; color: #ec2024">
                    Cashier Login
                  </p>
                </v-flex>
                <v-flex xs12 class="mt-1">
                  <v-text-field
                    style="font-size: 11px"
                    v-model="username"
                    :rules="[(v) => !!v || 'Required']"
                    label="Username"
                    required
                    dense
                    outlined
                    prepend-icon="person"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="password"
                    :rules="[(v) => !!v || 'Required']"
                    label="Password"
                    required
                    dense
                    outlined
                    type="password"
                    prepend-icon="lock"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-btn
                    @keyup.enter="cashierLoginLoading"
                    type="submit"
                    dark
                    small
                    text
                    class="pl-16 pr-16"
                    style="background-color: #ec2024"
                    @click="login"
                    id="loginBtn"
                    :loading="cashierLoginLoading"
                  >
                    <span class="text-capitalize">Login</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    currentLocation: "12345,7889",
    ipAddress: "",
    cashierLoginLoading: false,
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        this.cashierLoginLoading = true;
        try {
          await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `mutation{
       cashierLoginTryReport(
        user_name:"${this.username + "; Type: cashier"}",
        password: "${this.password}",
        locationCoordinat: "${this.currentLocation}",
        ip_address: "${this.ipAddress}",
        login_time: "${Date.now()}",

           )
        {
              statusMessage

           }
      }
      `,
            },
          });
        } catch (e) {
          alert(e);
        }

        try {
          var cashierLoginResult = await axios({
            method: "POST",
            url: this.$store.state.cashierURL,

            data: {
              query: `{
        cashierLoginNew(user_name:"${this.username}",password:"${this.password}"){
             cashier_id
             partner_id
             branch_id
             cashier_full_name
             cashier_phone
             user_name
             password
             loggin_time
             is_active
             is_loggedin
             is_allowed_anywhere
             logged_xy_coordinate

           }
      }
      `,
            },
          });

          if (cashierLoginResult.data.data.cashierLoginNew.length > 0) {
            if (
              cashierLoginResult.data.data.cashierLoginNew[0].is_active == 1
            ) {
              cashierLoginResult.data.data.cashierLoginNew[0].loggin_time =
                Date.now();
              this.$store.dispatch(
                "loginCashier",
                cashierLoginResult.data.data.cashierLoginNew[0]
              );
              this.$store.dispatch("sessionTime", Date.now());
              this.$router.push({ name: "home" });
            } else alert("You are blocked, please contact the admin");
          } else {
            alert(
              "Incorrect username and password, please enter correct values"
            );
          }
        } catch (err) {
          alert(err);
        }

        this.cashierLoginLoading = false;
      } else alert("Please fill all the required fields");
    },
    async getLocation() {
      const results = new Promise(function (resolve, reject) {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        function success(pos) {
          const crd = pos.coords;

          // console.log("Your current position is:");
          // console.log(`Latitude : ${crd.latitude}`);
          // console.log(`Longitude: ${crd.longitude}`);
          // console.log(`More or less ${crd.accuracy} meters.`);
          resolve(crd.latitude + "," + crd.longitude);
        }

        function error(err) {
          alert(`ERROR(${err.code}): ${err.message}`);
        }

        navigator.geolocation.getCurrentPosition(success, error, options);

        // var coordinats=  navigator.geolocation. getCurrentPosition(success, error, options)

        //   navigator.geolocation.getCurrentPosition(

        //     (position) => {
        //       resolve(position.coords.latitude + "," + position.coords.longitude);
        //     },
        //     (error) => {
        //       console.log(error.message);
        //     }
        //   );
      });

      return results;
    },

    async getIPAddress() {
      const results = new Promise(function (resolve, reject) {
        fetch("https://api.ipify.org?format=json")
          .then((x) => x.json())
          .then(({ ip }) => {
            resolve(ip);
          });
      });
      return results;
    },

    async distance(lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295; // Math.PI / 180
      var c = Math.cos;
      var a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },

    async calculateDifference(prevCordinates) {
      var isFound = false;
      var spilitedCoordinates = prevCordinates.split(":");
      for (let i = 0; i < spilitedCoordinates.length; i++) {
        var distance = Math.round(
          (await this.distance(
            this.currentLocation.split(",")[0],
            this.currentLocation.split(",")[1],
            spilitedCoordinates[i].split(",")[0],
            spilitedCoordinates[i].split(",")[1]
          )) * 1000
        );

        if (distance <= 500) isFound = true;
      }

      return isFound;
    },
  },
  async created() {
    // this.currentLocation = await this.getLocation();
    this.ipAddress = await this.getIPAddress();

    // alert(
    //   Math.round(
    //     (await this.distance(
    //       this.currentLocation.split(",")[0],
    //       this.currentLocation.split(",")[1],
    //       9.003122983279233,
    //       38.82421699638653
    //     )) * 1000
    //   )
    // );
    // this.$store.dispatch("loginCashier", false);
    // if (this.$store.state.cashier != "") this.$router.push({ name: "home" });
  },
};
</script>
<style scoped>
#login {
  max-width: 800px;
  margin: auto;
  border-radius: 5px;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
#loginCard {
  background: linear-gradient(
    90deg,
    rgba(29, 39, 88, 255) 31.35%,
    white 31.35%
  );
}
#formId {
  max-width: 350px;
  margin: auto;
}

#loginContainer {
  background: linear-gradient(90deg, rgba(29, 39, 88, 255) 39%, white 39%);
  height: 100%;
}
</style>
